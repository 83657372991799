import {
  SAVE_DATA_REDUX,
  DISCOVER_EMPLOYEES_LIST_REDUX,
  POSITIONS_LIST_DATA_REDUX,
  EMPLOYEE_COLLECTION_DATA_REDUX,
  EMPLOYER_COLLECTION_DATA_REDUX,
} from "../types/types";
import {
  SERVER_REQUEST,
  DISCOVER_EMPLOYEES_API,
  DISCOVER_POSITIONS_API,
  SAVE_EMPLOYEE_COLLECTION_API,
  SAVE_EMPLOYER_COLLECTION_API,
  GET_EMPLOYEE_COLLECTION_API,
  GET_EMPLOYER_COLLECTION_API,
  REMOVE_EMPLOYEE_COLLECTION_API,
  REMOVE_EMPLOYER_COLLECTION_API,
} from "../../shared/Backend";

export const getEmployeesListApiCall =
  (address, params) => async (dispatch) => {
    dispatch({
      type: DISCOVER_EMPLOYEES_LIST_REDUX,
      loader: true,
      message: null,
    });
    try {
      const response = await SERVER_REQUEST(
        null,
        `${DISCOVER_EMPLOYEES_API}?address=${address}&recommended=${params.recommended}&title=${params.title}&city=${params.city}&work_experience_low=${params.minYears}&work_experience_high=${params.maxYears}&skills=${String(params.keywords)}`,
        "GET"
      );
      if (response.message === "ok") {
        dispatch({
          type: DISCOVER_EMPLOYEES_LIST_REDUX,
          data: response.employees,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: DISCOVER_EMPLOYEES_LIST_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: DISCOVER_EMPLOYEES_LIST_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const getDiscoverPositionsListApiCall =
  (address, params) => async (dispatch) => {
    dispatch({ type: POSITIONS_LIST_DATA_REDUX, loader: true, message: null });
    try {
      const response = await SERVER_REQUEST(
        null,
        `${DISCOVER_POSITIONS_API}?address=${address}&recommended=${params.recommended}&title=${params.title}&city=${params.city}&work_type=${params.jobType}&salary_type=${params.salaryType}&salary_low=${params.salaryLow}&salary_high=${params.salaryHigh}&skills=${params.keywords.join(',')}`,
        "GET"
      );
      if (response.message === "ok") {
        dispatch({
          type: POSITIONS_LIST_DATA_REDUX,
          data: response.positions,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: POSITIONS_LIST_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: POSITIONS_LIST_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const savePositionCollectionApiCall =
  (body, navigation) => async (dispatch) => {
    dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
    try {
      const response = await SERVER_REQUEST(
        body,
        SAVE_EMPLOYEE_COLLECTION_API,
        "POST"
      );
      if (response.message === "ok") {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const saveEmployeeCollectionApiCall =
  (body, navigation) => async (dispatch) => {
    dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
    try {
      const response = await SERVER_REQUEST(
        body,
        SAVE_EMPLOYER_COLLECTION_API,
        "POST"
      );
      if (response.message === "ok") {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const getEmployeeCollectionApiCall =
  (address, params) => async (dispatch) => {
    dispatch({
      type: EMPLOYEE_COLLECTION_DATA_REDUX,
      loader: true,
      message: null,
    });
    try {
      const response = await SERVER_REQUEST(
        null,
        `${GET_EMPLOYEE_COLLECTION_API}?address=${address}`,
        "GET"
      );
      if (response.message === "ok") {
        dispatch({
          type: EMPLOYEE_COLLECTION_DATA_REDUX,
          data: response.positions,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: EMPLOYEE_COLLECTION_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: EMPLOYEE_COLLECTION_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const getEmployerCollectionApiCall =
  (address, params) => async (dispatch) => {
    dispatch({
      type: EMPLOYER_COLLECTION_DATA_REDUX,
      loader: true,
      message: null,
    });
    try {
      const response = await SERVER_REQUEST(
        null,
        `${GET_EMPLOYER_COLLECTION_API}?address=${address}`,
        "GET"
      );
      if (response.message === "ok") {
        dispatch({
          type: EMPLOYER_COLLECTION_DATA_REDUX,
          data: response.employees,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: EMPLOYER_COLLECTION_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: EMPLOYER_COLLECTION_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const removePositionCollectionApiCall =
  (body, navigation) => async (dispatch) => {
    dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
    try {
      const response = await SERVER_REQUEST(
        body,
        REMOVE_EMPLOYEE_COLLECTION_API,
        "POST"
      );
      if (response.message === "ok") {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const removeEmployerCollectionApiCall =
  (body, navigation) => async (dispatch) => {
    dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
    try {
      const response = await SERVER_REQUEST(
        body,
        REMOVE_EMPLOYER_COLLECTION_API,
        "POST"
      );
      if (response.message === "ok") {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };
