import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeWorkListApiCall,
  removeWorkApiCall,
} from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";

const WorkingExperienceModal = ({ setEditItem }: any) => {
  const dispatch = useDispatch();

  const { workListData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );

  const onPressDelete = async (id: number) => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      work_id: id,
    };
    await removeWorkApiCall(body)(dispatch);
    await getEmployeeWorkListApiCall(address)(dispatch);
  };

  const formateDate = (item: any) => {
    const fromTime =
      new Date(item?.fromTime).getFullYear() +
      "/" +
      (new Date(item?.fromTime).getMonth() + 1);

    const toTime =
      new Date(item?.toTime).getFullYear() +
      "/" +
      (new Date(item?.toTime).getMonth() + 1);

    return fromTime + " - " + toTime;
  };

  const fetchData = async () => {
    const address = await GET_ADDRESS();
    getEmployeeWorkListApiCall(address)(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="modal fade workExperience modalwidth444"
      tabIndex={-1}
      aria-labelledby="workExperienceLabel"
      aria-hidden={true}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="workexperiencDiaBox">
              <h5 className="modal-title black0d fontfamily-semibold mb-15 fontsize-16 text-center">
                Working Experience
              </h5>
              {workListData?.map((item: any) => (
                <div>
                  <div className="wExpRow d-flex align-items-center pt-15 pb-15">
                    <img
                      src={require("../../assets/images/products/product1.png").default}
                      alt=""
                      className="WorkingExperienceImg"
                    />
                    <div className="pl-15">
                      <p className="fontfamily-semibold mb-1 fontsize-14 black">
                        {item?.position}
                      </p>
                      <p className="fontfamily-medium mb-1 fontsize-14 gray80">
                        {item?.project}
                      </p>
                      <p className="fontfamily-regular mb-0 fontsize-12 gray80">
                        {formateDate(item)}
                      </p>
                    </div>
                    <div className="wExpActioIconBox d-flex align-items-end ml-auto justify-content-end">
                      <div
                        onClick={() => setEditItem(item)}
                        data-bs-toggle="modal"
                        data-bs-target=".aadworkExperience"
                      >
                        <img
                          src={
                            require("../../assets/images/structure/edit-blue.svg")
                              .default
                          }
                          alt=""
                          className="editIcon cursor-p mr-10"
                          style={{ marginBottom: 5 }}
                        />
                      </div>
                      <div onClick={() => onPressDelete(item?.id)}>
                        <img
                          src={
                            require("../../assets/images/structure/delete.svg")
                              .default
                          }
                          alt=""
                          className="deleteIcon cursor-p"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-50">
              <button
                className="btn width120"
                data-bs-toggle="modal"
                data-bs-target=".aadworkExperience"
              >
                ADD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingExperienceModal;
