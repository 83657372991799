import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getSkillsArrayApiCall,
  postEmployeeSkillsApiCall,
} from "../../redux/actions";
import { BACKGROUND_COLOR } from "../../shared/Colors";
import { GET_ADDRESS, GET_CURRENT_LANGUAGE } from "../../shared/Storage";
import SearchResultItem from "../SearchResultItem/SearchResultItem";

const KeywordsModal = ({ setKeyWords }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [mySkills, setMySkills] = useState<string[]>([]);

  const { skillsData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );
  const { employeeData } = useSelector((item: any) => item?.userReducer);

  const onConfirmHandler = (item: string) => {
    if (!mySkills.includes(item)) {
      mySkills.push(item);
      setValue("");
    } else {
      alert("You have already added this skill!!");
    }
  };

  // const saveSkills = async () => {
  //   const address = await GET_ADDRESS();
  //   const body = {
  //     address: address,
  //     skills: String(mySkills),
  //   };
  //   console.log(body);
  //   postEmployeeSkillsApiCall(body)(dispatch);
  //   await getEmployeeDataApiCall(address)(dispatch);
  // };

  const onDeleteSkill = (item: string) => {
    let aux = [...mySkills];
    const index = aux.indexOf(item);
    aux.splice(index, 1);
    setMySkills(aux);
  };

  const searchForSkill = () => {
    if (value.length > 0) {
      const searchText = value.toLowerCase();
      let filtredSkills: string[] = [];
      skillsData?.map((item: string) => {
        const currentItem = item.toLowerCase();
        if (currentItem === searchText || currentItem.startsWith(searchText)) {
          filtredSkills.push(item);
        }
      });
      setSearchResult(filtredSkills);
    } else {
      setSearchResult(skillsData);
    }
  };

  const fetchSkills = async () => {
    const language = await GET_CURRENT_LANGUAGE();
    getSkillsArrayApiCall(language)(dispatch);
  };

  useEffect(() => {
    fetchSkills();
    if (employeeData?.skills) {
      setMySkills(employeeData.skills.split(","));
    }
  }, []);

  useEffect(() => {
    searchForSkill();
  }, [skillsData, value]);

  return (
    <div
      className="modal fade keywordsDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="keywordsDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="keywordsbox">
              <h5 className="modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                Keywords
              </h5>
              <div className="keywordsSearchbox d-flex align-items-center justify-content-center">
                <div className="form-group mb-0 form-input searchInput">
                  <input
                    type="text"
                    placeholder="Search for skills"
                    className="form-control"
                    value={value}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                    }}
                  />
                  <img
                    src={
                      require("../../assets/images/structure/search.svg")
                        .default
                    }
                    alt=""
                    className="searchIcon"
                  />
                </div>
                <button
                  className="btn outline-blue"
                  onClick={() => onConfirmHandler(value)}
                >
                  Save
                </button>
              </div>
              {value.length !== 0 && (
                <div
                  style={{
                    width: "80%",
                    height: "70%",
                    position: "absolute",
                    backgroundColor: BACKGROUND_COLOR,
                    opacity: 0.9,
                    paddingLeft: 48,
                    left: 50,
                  }}
                >
                  {searchResult?.map((item, index) => (
                    <SearchResultItem
                      key={index}
                      onPress={() => onConfirmHandler(item)}
                      text={value}
                      result={item}
                    />
                  ))}
                </div>
              )}
              <div className="d-flex flex-wrap mt-15">
                {mySkills?.map((item: string, index: number) => (
                  <div className="tags-gray" key={index}>
                    {item}{" "}
                    <span
                      onClick={() => onDeleteSkill(item)}
                    >
                      <img
                        src={
                          require("../../assets/images/structure/close-black.svg")
                            .default
                        }
                        alt=""
                        className="closetag"
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div
                className="mt-180 text-center"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <button
                  className="btn width200"
                  onClick={() => setKeyWords([...mySkills])}
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordsModal;
