import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountPositionsApiCall, getPositionsListApiCall, removePositionApiCall } from "../../redux/actions";
import { GET_ADDRESS, GET_CURRENT_ROLE } from "../../shared/Storage";

const workTypes = [
  "Full-time",
  "Part-time",
  "Contract",
  "Internship",
  "Volunteer",
];

const cityTypes = ["Any", "Remote", "On-site"];

const salaryTypes = ["Hour", "Month"];

const PostedPositionsModal = () => {
  const dispatch = useDispatch();
  const { positionsListData } = useSelector(
    (item: any) => item?.employerProfileReducer
  );

  const getPostedPositions = async () => {
    const address = await GET_ADDRESS();
    const role = await GET_CURRENT_ROLE();
    if(role === '0') {
      await getPositionsListApiCall(address)(dispatch);
    }
    
  };

  const formateDate = (date: string) => {
    const postedDate = new Date(date);
    const formatedDate = new Date(postedDate).toLocaleDateString("en-ZA");
    return formatedDate;
  };

  const onPressDelete = async (id: string) => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      position_id: id,
    };
    await removePositionApiCall(body)(dispatch);
    await getPositionsListApiCall(address)(dispatch);
    await getCountPositionsApiCall(address)(dispatch);
  };

  useEffect(() => {
    getPostedPositions();
  }, []);
  return (
    <div
      className="modal fade postedposition modalwidth828"
      tabIndex={-1}
      aria-labelledby="postedpositionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="positionItemBox">
              <h5 className="modal-title black0d fontfamily-semibold mb-30 fontsize-16 text-center">
                Saved Positions
              </h5>
              <div className="row">
                {positionsListData?.map((item: any, index: number) => (
                  <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="productListBlock">
                      <div className="product-row1 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/products/product1.png").default}
                          className="prodImage"
                          alt=""
                        />
                        <div className="pl-15">
                          <p className="fontsize-16 black fontfamily-medium mb-0">
                            {item?.title}
                          </p>
                          <p className="fontsize-14 gray82 fontfamily-medium mb-0">
                            {item?.category}{" "}
                            <img
                              src="images/structure/star.svg"
                              alt=""
                              className="starIcon"
                            />
                          </p>
                          <p className="fontsize-12 gray82 fontfamily-regular mb-0">
                            {`Posted ${formateDate(item?.updatedAt)}`}
                          </p>
                        </div>
                        <div className="likebox ml-auto" onClick={() => onPressDelete(item?.id)}>
                          <img
                            src={
                              require("../../assets/images/structure/delete-new.svg")
                                .default
                            }
                            alt=""
                            //className="saveIcon1"
                          />
                          {/* <img
                            src={require("../../assets/images/structure/save-pink.svg").default}
                            alt=""
                            className="saveIcon2"
                          /> */}
                        </div>
                      </div>
                      <div className="product-row3 d-flex align-items-center">
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="chips chips-fill-orange mb-0">
                            {workTypes[item?.workType]}
                          </span>
                          <span className="chips chips-fill-green mb-0">
                            {cityTypes[item?.cityType]}
                          </span>
                        </div>
                        <div className="ml-auto">
                          <p className="fontsize-16 fontfamily-semibold blue70 mb-0">
                            {`$${item?.salaryLow}~$${item?.salaryHigh} / ${
                              salaryTypes[item?.salaryType]
                            }`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostedPositionsModal;
