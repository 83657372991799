const WorkExpRangeModal = ({
  setExperienceType,
  minYears,
  maxYears,
  setMinYears,
  setMaxYears,
}: any) => {
  return (
    <div
      className="modal fade workExperenceDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="workExperenceDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="salaryRangebox">
              <h5 className="modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                Work Experience
              </h5>
              <div className="selectchips d-flex align-items-center justify-content-between">
                <div
                  className="category-chips-box"
                  onClick={() => setExperienceType(0)}
                >
                  <input type="radio" name="slectsalary" id="hide" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Any
                    </p>
                  </div>
                </div>
                <div
                  className="category-chips-box"
                  onClick={() => setExperienceType(1)}
                >
                  <input type="radio" name="slectsalary" id="show" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Range
                    </p>
                  </div>
                </div>
              </div>
              <div className="saleryrangepricecol mt-15 d-flex align-items-center justify-content-between">
                <input
                  type="text"
                  placeholder="Min"
                  onChange={(evt) => setMinYears(evt.target.value)}
                  value={minYears}
                />{" "}
                <img
                  src={require("../../assets/images/structure/line.png").default}
                  alt=""
                  className="line"
                />{" "}
                <input
                  type="text"
                  placeholder="Max"
                  onChange={(evt) => setMaxYears(evt.target.value)}
                  value={maxYears}
                />
                <p
                  className="font-medium years-text fofontsize-14 text-uppercase fontfamily-bold mb-0"
                  style={{ color: "rgba(112, 78, 248, 0.995)" }}
                >
                  Years
                </p>
              </div>
              <div
                className="mt-100 text-center next-btn btn-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <button className="btn width200">CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExpRangeModal;
