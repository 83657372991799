const VerificationCodeModal = () => {
  return (
    <div
      className="modal fade submittedModal modalwidth428"
      tabIndex={-1}
      aria-labelledby="submittedModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxSubmit">
          <h4
            className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center"
            style={{ color: "rgba(112, 78, 248, 0.995)" }}
          >
            <i className="mr-10">Submitted</i>!
          </h4>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <p className="mt-25 text-center" style={{ color: "#6D6D6D" }}>
              Your submisson is under review. Please
              <br /> wait for our confirmation ！
            </p>
          </div>
          <div className="mb-40 text-center" >
            <button className="btn width200">Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeModal;
