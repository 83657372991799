import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  postEmployeeBasicInfoApiCall,
  getEmployeeDataApiCall,
} from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";

const EmployeeBasicInfoModal = () => {
  const dispatch = useDispatch();
  const { employeeData } = useSelector((item: any) => item.userReducer);
  const [isEmployeeCreated, setIsEmployeeCreated] = useState(true);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [saving, setSaving] = useState(false);

  const onPressSave = async () => {
    setSaving(true);
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      bio: bio,
      name: name,
    };
    await postEmployeeBasicInfoApiCall(body)(dispatch);
    await getEmployeeDataApiCall(address)(dispatch);
    setIsEmployeeCreated(true);
    setSaving(false);
    document.querySelector<HTMLElement>('.employee-basicinfo-close')?.click();
  };

  useEffect(() => {
    if (employeeData) {
      if (employeeData.err) {
        setIsEmployeeCreated(false);
      } else {
        setIsEmployeeCreated(true);
        setName(employeeData?.name);
        setBio(employeeData?.bio);
      }
    }
  }, [employeeData]);
  return (
    <div
      className={
        "modal fade" +
        (!isEmployeeCreated ? " show d-block " : " ") +
        "basicInfo modalwidth"
      }
      tabIndex={-1}
      aria-labelledby={"BasicInfoLabel"}
      //aria-hidden={true}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxbasicInfo">
          <h5 className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center">
            Your Basic Info
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close employee-basicinfo-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsEmployeeCreated(true)}
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group mt-20">
              <input
                type="text"
                className="form-control formInput "
                placeholder="Your Name"
                aria-label="Verify Your Offcial Twitter"
                onChange={(evt) => setName(evt.target.value)}
                value={name}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control formInput "
                rows={5}
                id="comment"
                name="text"
                placeholder="Personal description"
                onChange={(evt) => setBio(evt.target.value)}
                value={bio}
              ></textarea>
            </div>

            <div className="mt-20 text-center">
              <button className="btn width200" onClick={() => onPressSave()} disabled={saving}>
                Save
                {saving && <span className="icon-spinner" ></span>}                
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeBasicInfoModal;
