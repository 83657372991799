import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEmployeeDataApiCall, getEmployerDataApiCall, updateUserCityApi } from "../../redux/actions";
import { CHANGE_USER_CITY } from "../../redux/types/types";
import { BACKGROUND_COLOR } from "../../shared/Colors";
import { GET_ADDRESS, GET_CURRENT_ROLE } from "../../shared/Storage";
import SearchResultItem from "../SearchResultItem/SearchResultItem";

const CitySelectionModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popularCities, setPopularCities] = useState(["Remote, Remote"]);
  const [selectedCity, setSelectedCity] = useState("");
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [value, setValue] = useState("");
  const { cityListData } = useSelector((item: any) => item.userReducer);
  const [confirming, setConfirming] = useState(false);

  const searchForCity = () => {
    if (value.length > 0) {
      const searchText = value.toLowerCase();
      let filtredCities: string[] = [];
      cityListData?.map((item: string) => {
        const currentItem = item.toLowerCase();
        if (currentItem === searchText || currentItem.startsWith(searchText)) {
          filtredCities.push(item);
        }
      });
      setSearchResult(filtredCities);
    } else {
      setSearchResult(cityListData);
    }
  };

  const onPressItem = (item: string) => {
    setValue("");
    setSelectedCity(item);
  };

  const onConfirmHandler = async () => {
    setConfirming(true);
    const address = await GET_ADDRESS();
    const role = await GET_CURRENT_ROLE();
    dispatch({ type: CHANGE_USER_CITY, payload: selectedCity });
    const body = {
      address: address,
      city: selectedCity
    }
    await updateUserCityApi(body)(dispatch);
    if(role === '1') {
      await getEmployeeDataApiCall(address)(dispatch)
    } else {
      await getEmployerDataApiCall(address)(dispatch);
    }
    setConfirming(false);
  };

  useEffect(() => {
    if (cityListData) {
      setPopularCities([...popularCities, ...cityListData.slice(0, 6)]);
    }
    return () => {
      setPopularCities(["Remote, Remote"]);
    };
  }, [cityListData]);

  useEffect(() => {
    searchForCity();
  }, [value]);

  return (
    <div
      className="modal fade citiesDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="citiesDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="citiesbox">
              <div className="citiessearchbox d-flex align-items-center justify-content-center">
                <div className="form-group mb-0 form-input searchInput w-100">
                  <input
                    type="text"
                    placeholder="Enter a city name"
                    className="form-control"
                    value={selectedCity}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                      setSelectedCity(evt.target.value);
                    }}
                  />
                  <img
                    src={
                      require("../../assets/images/structure/search.svg")
                        .default
                    }
                    alt=""
                    className="searchIcon"
                  />
                </div>
              </div>
              {value.length !== 0 && (
                <div style={{
                  width: "80%",
                  height: '80%',
                  position: 'absolute',
                  backgroundColor: BACKGROUND_COLOR,
                  opacity: 0.9,
                  paddingLeft: 48,
                  left: 50,
                }}>
                  {searchResult?.map((item, index) => (
                    <SearchResultItem
                      key={index}
                      onPress={() => onPressItem(item)}
                      text={selectedCity}
                      result={item}
                    />
                  ))}
                </div>
              )}
              <div className="divider mt-25 mb-25"></div>
              <h5 className="black0d fontfamily-semibold mb-15 fontsize-16">
                Popular Cities
              </h5>
              <div className="d-flex flex-wrap mt-15">
                {popularCities.map((item, index) => (
                  <div
                    key={index}
                    className="cityChips"
                    style={{
                      backgroundColor:
                        item === selectedCity ? "rgba(112, 78, 248, 0.25)" : "",
                    }}
                    onClick={() => onPressItem(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="mt-30 text-center" data-bs-dismiss="modal">
                <button className="btn width200" onClick={onConfirmHandler} disabled={confirming}>
                  CONFIRM
                  {confirming && <span className="icon-spinner" ></span>}                
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitySelectionModal;

// const styles = {
//   searchWrapper: {
//     width: "100%",
//     //height: '20%',
//     //Position: 'absolute',
//     backgroundColor: BACKGROUND_COLOR,
//     opacity: 0.9,
//     paddingLeft: 48,
//     left: 20,
//   },
// };
