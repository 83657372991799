export const PRIMARY_COLOR = '#6B4EFF';
export const PRIMARY_BLUR_COLOR = 'rgba(107, 78, 255, 0.51)';
export const PRIMARY_BLUR1_COLOR = 'rgba(112, 78, 248, 0.25)';
export const WHITE_COLOR = '#FFFFFF';
export const BLACK_COLOR = '#000000';
export const SKY_LIGHT_COLOR = '#E3E5E5';
export const GRAY_COLOR = '#72777A';
export const GRAY1_COLOR ='#808080';
export const GRAY2_COLOR = '#B5B5B5';
export const GRAY3_COLOR = 'rgba(181, 181, 181, 0.5)';
export const NATURAL_CONTROL_COLOR40 = '#F2F3F5';
export const NATURAL_CONTROL_COLOR70 = '#DBDDE5';
export const BACKGROUND_COLOR = '#EFEEF5';
export const PRIMARY_BRAND = '#704EF8';
export const ORANGE_COLOR = '#FC674B';
export const HIGH_LIGHT_COLOR = '#FFC21F' 





