export const REGISTER_REDUX_AUTH = 'REGISTER_REDUX_AUTH';
export const SAVE_DATA_REDUX = 'SAVE_DATA_REDUX';

export const USER_DATA_REDUX = 'USER_DATA_REDUX';
export const USERS_DATA_ARRAY_REDUX = 'USERS_DATA_ARRAY_REDUX';
export const EMPLOYEE_DATA_REDUX = 'EMPLOYEE_DATA_REDUX';
export const EMPLOYER_DATA_REDUX = 'EMPLOYER_DATA_REDUX';
export const EXTENDED_DATA_REDUX = 'EXTENDED_DATA_REDUX';

export const POSITIONS_LIST_DATA_REDUX = 'POSITIONS_LIST_DATA_REDUX';
export const POSITION_DATA_REDUX = 'POSITION_DATA_REDUX';
export const COUNT_POSITIONS_DATA_REDUX = 'COUNT_POSITIONS_DATA_REDUX';

export const SKILL_LIST_DATA_REDUX = 'SKILL_LIST_DATA_REDUX';

export const POSITION_LIST_DATA_REDUX = 'POSITION_LIST_DATA_REDUX';
export const CITY_LIST_DATA_REDUX = 'CITY_LIST_DATA_REDUX';

export const CHANGE_USER_CITY = 'CHANGE_USER_CITY/REDUX';
export const CHANGE_EXPERIENCE_YEARS = 'CHANGE_EXPERIENCE_YEARS';

export const DISCOVER_EMPLOYEES_LIST_REDUX = 'DISCOVER_EMPLOYEES_LIST_REDUX';

export const EMPLOYEE_COLLECTION_DATA_REDUX = 'EMPLOYEE_COLLECTION_DATA_REDUX';
export const EMPLOYER_COLLECTION_DATA_REDUX = 'EMPLOYER_COLLECTION_DATA_REDUX';
export const COUNT_EMPLOYER_COLLECTION_DATA_REDUX = 'COUNT_EMPLOYER_COLLECTION_DATA_REDUX';
export const COUNT_EMPLOYEE_COLLECTION_DATA_REDUX = 'COUNT_EMPLOYEE_COLLECTION_DATA_REDUX';
export const COUNT_CHAT_REDUX = 'COUNT_CHAT_REDUX';

export const EMPLOYEE_EDUCATION_LIST_DATA_REDUX = 'EMPLOYEE_EDUCATION_LIST_DATA_REDUX'
export const EMPLOYEE_WORK_LIST_DATA_REDUX = 'EMPLOYEE_WORK_LIST_DATA_REDUX';

export const EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX = 'EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX';

export const EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX = 'EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX';