const EmptyChat = () => {
  return (
    <>
  
      <div className="main-wrapper bggrayfa empty-wrapper">
        <section className="chat-sec">
          <div className="container">
            <div className="row">
              <div className="col-xl-11 col-lg-11 col-md-12 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="text-center chat-header">
                  <p className="fontsize-16 fontfamily-semibold black0d mb-25">
                    Messages
                  </p>
                  <div className="mobile-only">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="chat"
                        placeholder="Search a chat or message"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center chatemptyBox">
                  <div className="chat_empty">
                    <h3 className="mobile-only">Empty</h3>
                    <p className="gray80 fontsize-12 fontfamily-regular mb-25 mt-0">
                      You currently have no incoming messages
                    </p>
                    <img src={require("../../assets/images/structure/chatbox.png").default} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmptyChat;
