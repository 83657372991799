import { useEffect, useState } from "react";

const NotificationModal = ({errorMsg, closeNotify}: any) => {

  useEffect(() => {
  }, []);
  return (
    <div
      className={
        "modal fade show d-block modalwidth notify-modal"
      }
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <h5 className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center">
            Error
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => closeNotify()}
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body text-center">
            {errorMsg}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
