const SalaryRangeModal = ({setSalaryType, salaryLow, salaryHigh, setSalaryLow, setSalaryHigh, setConfirmSalary}: any) => {
  return (
    <div
      className="modal fade salaryRangeDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="salaryRangeDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="salaryRangebox">
              <h5 className="text-xs-center modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                Salary Range
              </h5>
              <div className="selectchips d-flex align-items-center justify-content-between">
                <div className="category-chips-box" onClick={() => setSalaryType(null)}>
                  <input type="radio" name="slectsalary" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Any
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setSalaryType(0)}>
                  <input type="radio" name="slectsalary" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Hourly
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setSalaryType(1)}>
                  <input type="radio" name="slectsalary" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Monthly
                    </p>
                  </div>
                </div>
              </div>
              <div className="saleryrangepricecol mt-15 d-flex align-items-center justify-content-between">
                <input type="text" placeholder="Min" value={salaryLow} onChange={evt => setSalaryLow(evt.target.value)} />{" "}
                <img
                  src={require("../../assets/images/structure/line.png").default}
                  alt=""
                  className="line"
                />{" "}
                <input type="text" placeholder="Max" value={salaryHigh} onChange={evt => setSalaryHigh(evt.target.value)} />{" "}
                <p
                  className="fofontsize-16 text-uppercase fontfamily-bold mb-0"
                  style={{ color: "rgba(112, 78, 248, 0.5)" }}
                >
                  IN USD
                </p>
              </div>
              <div className="mt-100 text-center next-btn btn-lg" onClick={() => {setConfirmSalary(true)}}>
                <button className="btn width200"           data-bs-toggle="modal"
                      data-bs-target=".salaryRangeDialog">CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryRangeModal;
