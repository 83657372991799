import React from "react";
import { BLACK_COLOR, GRAY1_COLOR } from "../../shared/Colors";

const SearchResultItem = ({ text = "", result = "", onPress }: any) => {
  const value = text.toLocaleLowerCase();
  const res = result.toLocaleLowerCase();

  return (
    <div style={styles.container} onClick={onPress}>
      {res.indexOf(value) !== -1 ? (
        <div>
          <span style={{...styles.text}} >{result.slice(0, res.indexOf(value))}</span>
            <span style={{...styles.text, color: GRAY1_COLOR }}>
                {result.slice(res.indexOf(value), res.indexOf(value) + value.length)}
            </span>
            <span style={styles.text}>
                {result.slice(res.indexOf(value) + value.length)}
            </span>
        </div>
      ) : (
        <div style={styles.text}>{result}</div>
      )}
    </div>
  );
};

const styles = {
  container: {
    marginBottom: 10,
    paddingHorizontal: 10,
    cursor: 'pointer'
  },
  text: {
    fontSize: 15,
    fontWeight: "400",
    color: BLACK_COLOR,
  },
};

export default SearchResultItem;
