import {REGISTER_REDUX_AUTH} from '../types/types';

const initialState = {
  loading: false,
  errorMessage: null,
  userData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_REDUX_AUTH:
      return {
        ...state,
        userData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
