import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import { PagesProps } from "../register/register.model";
import { GET_CURRENT_ROLE, SET_CURRENT_ROLE } from "../../shared/Storage";

const ChooseRole = ({ handleSteps }: PagesProps) => {
  const { t } = useTranslation();
  //const [role, setRole] = useState('1');

  const onChooseRole = (value: String) => {
    SET_CURRENT_ROLE(value);
  };

  useEffect(() => {
    (async () => {
      const role = await GET_CURRENT_ROLE();
      if(!role) {
        SET_CURRENT_ROLE("1");
      }
    })()
    
  }, []);

  return (
    <>
      <div className="main-wrapper front-layout">
        <section className="choose-option-sec1 position-relative">
          <div className="back-btn">
            <img
              src={
                require("../../assets/images/structure/back-icon.svg").default
              }
              alt=""
            />
          </div>
          <div className="container">
            <div className="row h-100">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mx-auto h-100">
                <div className="text-center choose-box">
                  <h5 className="blue70 fontfamily-blackitalic mb-55 fontsize-22 magic-heading">
                    What are you looking for？
                  </h5>
                  <div className="mb-10">
                    <div
                      className="category-chips-box"
                      onClick={() => onChooseRole("1")}
                    >
                      <input type="radio" name="categorySelect"  />
                      <div className="category-chips">
                        <p className="fontsize-16 fontfamily-semibold blue70 mb-20">
                          find a job
                        </p>
                        <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                          I want to find a job for myself
                        </p>
                      </div>
                    </div>
                    <div
                      className="category-chips-box"
                      onClick={() => onChooseRole("0")}
                    >
                      <input type="radio" name="categorySelect" />
                      <div className="category-chips">
                        <p className="fontsize-16 fontfamily-semibold blue70 mb-20">
                          Hire people
                        </p>
                        <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                          I want to hire people
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn next-btn"
                    onClick={() => handleSteps(1)}
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default ChooseRole;
