import { useEffect, useState } from "react";

const LoadingOverlay = () => {

  const [overlayTop, setOverLayTop] = useState(0);

  useEffect(() => {
    const height = document.querySelector('.header')?.clientHeight || 0;
    setOverLayTop(height)
  }, [])

  return (
    <div className="modal loading-modal home-modal"
        style={{top : overlayTop}}
      >
      <div className="modal-dialog-centered">
          {<span className="icon-spinner" ></span>}                
      </div>
    </div>
  );
};

export default LoadingOverlay;
