import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CitySelectionModal from "../../components/Modals/CitySelectionModal";
import EmployerBasicInfoModal from "../../components/Modals/EmployerBasicInfoModal";
import {
  getCityArrayApiCall,
  getCountChatApiCall,
  getCountPositionsApiCall,
  getCountSavedTalentsApiCall,
  getEmployerDataApiCall,
  getUserDataApiCall
} from "../../redux/actions";
import { GET_ADDRESS, GET_CURRENT_LANGUAGE, GET_CURRENT_ROLE, GET_OAUTH_TOKEN, REMOVE_OAUTH_TOKEN } from "../../shared/Storage";
import { useTranslation } from "react-i18next";
import PostNewPositionModal from "../../components/Modals/PostNewPositionModal";
import { useNavigate } from "react-router-dom";
import AccountVerificationModal from "../../components/Modals/AccountVerificationModal";
import EmailVerificationModal from "../../components/Modals/EmailVerificationModal";
import VerificationCodeModal from "../../components/Modals/VerificationCodeModal";
import VerificationSuccessModal from "../../components/Modals/VerificationSuccessModal";
import VerificationFailedModal from "../../components/Modals/VerificationFailedModal";
import PostedPositionsModal from "../../components/Modals/PostedPositionsModal";
import UploadImagePreviewModal from "../../components/Modals/UploadImagePreviewModal";
import SavedTalentsModal from "../../components/Modals/SavedTalentsModal";
import LanguageSelectionModal from "../../components/Modals/LanguageSelectionModal";

const EmployerProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const inputFile = useRef<HTMLInputElement>(null);

  const [oauthAppName, setOauthAppName] = useState('');

  const [isTwitterClick, setTwitterClick] = useState(false);
  const [isEmailClick, setEmailClick] = useState(false);
  const [isFormClick, setFormClick] = useState(false);

  const { employerData, userData } = useSelector(
    (item: any) => item.userReducer
  );

  const { city, countChatData } = useSelector((item: any) => item?.userReducer);

  const {countSavedTalents, countPositionsData,} = useSelector((item:any) => item?.employerProfileReducer)

  const checkProfileImageSrc = () => {
    return employerData?.image
      ? employerData?.image
      : require("../../assets/images/structure/succes.png").default;
  };

  const [imgSrc, setImgSrc] = useState('')
  const [imgFile, setImgFile] = useState<File>()

  const onImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgFile(e.target.files[0]);
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
       
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const fetchData = async () => {
    const address = await GET_ADDRESS();
    const role = await GET_CURRENT_ROLE();
    const language = await GET_CURRENT_LANGUAGE();
    if (role === "0") {
      await getEmployerDataApiCall(address)(dispatch);
      await getUserDataApiCall(address)(dispatch);
      await getCityArrayApiCall(language)(dispatch);

      await Promise.all([
        getCountPositionsApiCall(address)(dispatch),
        getCountSavedTalentsApiCall(address)(dispatch),
        getCountChatApiCall(address)(dispatch)
      ]);

    }
  };

  const handleOauthPress = async (appName: string) => {
    if (isEmailClick || isTwitterClick || isFormClick) {
      return;
    }

    setOauthAppName(appName);
    switch(appName) {
      case 'twitter':
        setTwitterClick(true);
        break;
      case 'email':
        setEmailClick(true);
        break;
      case 'form':
        setFormClick(true);  
        break;
      default:
    }

    const address = await GET_ADDRESS();
    const linkingURL = `${process.env.REACT_APP_API_KEY}${appName}/auth0?address=${address}&is_mobile=0`;
    
    REMOVE_OAUTH_TOKEN();
    window.open(linkingURL, '_blank');
  };

  useEffect(() => {
    fetchData();

    const handleStorageChange = async() => {
      const oauth_token = await GET_OAUTH_TOKEN();
      if (oauth_token?.includes('isOauthVerified')) {
        const appName = oauth_token.split('_')[0];
        setTwitterClick(false);
        document.querySelector<HTMLElement>('#closeVerifyBtn')?.click();
        await fetchData();
      }
    }

    window.addEventListener('storage', handleStorageChange);
  }, []);

  return (
    <>
      <div className="main-wrapper bggrayfa employerProfile">
        <EmployerBasicInfoModal />
        <PostNewPositionModal />
        <CitySelectionModal />
        <AccountVerificationModal handleOauthPress={handleOauthPress} isTwitterClick={isTwitterClick} />
        <EmailVerificationModal />
        <VerificationCodeModal />
        <VerificationSuccessModal />
        <VerificationFailedModal />
        <PostedPositionsModal />
        <SavedTalentsModal />
        <LanguageSelectionModal />
        <UploadImagePreviewModal imgSrc={imgSrc} imgFile={imgFile} setImgSrc={setImgSrc}/>
        <section className="profile-sec1 profile-empty">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-11 col-md-12 col-md-12 col-sm-12 col-12 mx-auto profile-empty-wrap">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-md-12 col-sm-12 col-12 profile-left-col">
                    <div className="profile-graybox1">
                      <img
                        src={
                          require("../../assets/images/structure/edit.svg")
                            .default
                        }
                        alt=""
                        className="prfileedit cursor-p"
                        data-bs-toggle="modal"
                        data-bs-target=".edit"
                      />
                      <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-md-12 col-sm-12 col-12 mx-auto">
                          <div className="text-center">
                            <img
                               src={checkProfileImageSrc()}
                               className="profile-pImage"
                               style={{ borderRadius: 500 }}
                               alt=""
                               onClick={onImageClick}
                               //data-bs-toggle="modal"
                               //data-bs-target=".imagePreviewDialog"
                            />
                            <input
                              type="file"
                              accept="image/*"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onSelectFile}
                            />
                            <h5 className="fontsize-24 black mb-2 fontfamily-bold mt-10 text-height-24">
                              {employerData?.name}
                            </h5>
                            <p className="black fontfamily-semibold mb-15 fontsize-16 text-height-16">
                              {" "}
                              {employerData?.position}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="uniswaplabbox bgwhite around-15"
                        style={{ borderRadius: 16 }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={require("../../assets/images/products/product2.png").default}
                            alt=""
                            className="labImage"
                          />
                          <div className="pl-25">
                            <p className="fontsize-14 black mb-1 fontfamily-semibold text-height-14">
                              {employerData?.project}
                            </p>
                            <p className="fontsize-12 gray80 mb-1 fontfamily-medium d-flex align-items-center">
                              <img
                                src={
                                  require("../../assets/images/structure/twitter-blue.svg")
                                    .default
                                }
                                alt=""
                                className="mr-5"
                              />{" "}
                              {employerData?.twitter}
                            </p>
                            <p className="fontsize-12 gray80 mb-1 fontfamily-medium d-flex align-items-center text-underline">
                              <img
                                src={
                                  require("../../assets/images/structure/glob.svg")
                                    .default
                                }
                                alt=""
                                className="mr-5"
                              />{" "}
                              {employerData?.website}
                            </p>
                          </div>
                          <div className="ml-auto">
                          {employerData?.isTwitterVerified ||
                            employerData?.isWebsiteVerified ||
                              employerData?.isAdminVerified ? (
                              <></>
                            ) : (
                              <button
                              className="btn fill-orange"
                              data-bs-toggle="modal"
                              data-bs-target=".verifyModal"
                            >
                              Verify
                            </button>
                            )} 
                          </div>
                        </div>
                        <p className="fontsize-10 black mb-0 fontfamily-regular mt-20 text-height-10">
                          {employerData?.description}
                        </p>
                      </div>
                    </div>
                    <div className="profile-graybox2 d-flex align-items-center flex-wrap mt-25">
                      <div className="d-flex prfile-tag">
                        <img
                          src={
                            countSavedTalents ? require("../../assets/images/structure/collection-pink.svg")
                              .default : require("../../assets/images/structure/collection.svg")
                              .default
                          }
                          alt=""
                          className="collectionIcon mr-20"
                        />
                        <div
                          className={ countSavedTalents ? "positiontabs d-flex align-items-center" : "positiontabs d-flex align-items-center disabled-positiontabs" }
                          data-bs-toggle="modal"
                          data-bs-target=".Savedposition"
                        >
                          <p className="fontsize-16 black mb-0 fontfamily-semibold d-flex align-items-center w-100">
                            Saved Talents{" "}
                            <span className={(countSavedTalents ? "fontfamily-bold posNo ml-auto orangefc" : "grayb5 posNo ml-auto orangefc")}
                            >
                              {countSavedTalents}{" "}
                              <img
                                src="images/structure/arrow-prev.svg"
                                alt=""
                                className="arrow-prev ml-3"
                              />
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex ml-auto positionDisable prfile-tag">
                        <img
                          src={
                            countPositionsData ? require("../../assets/images/structure/breifcase.svg")
                              .default : require("../../assets/images/structure/breifcase-gray.svg")
                              .default
                          }
                          alt=""
                          className="applied mr-20"
                        />
                        <div
                          data-bs-toggle="modal" data-bs-target=".postedposition"
                          className={ countPositionsData ? "positiontabs d-flex align-items-center d-flex align-items-center" : "positiontabs d-flex align-items-center d-flex align-items-center disabled-positiontabs"}
                        >
                          <p className="fontsize-16 grayb5 mb-0 fontfamily-semibold d-flex align-items-center w-100">
                            Posted Positions{" "}
                            <span className={(countPositionsData ? "fontfamily-bold posNo ml-auto orangefc" : "grayb5 posNo ml-auto orangefc")} >
                              {countPositionsData}{" "}
                              <img
                                src="images/structure/arrow-prev.svg"
                                alt=""
                                className="arrow-prev ml-3"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex mt-25 positionDisable prfile-tag">
                        <img
                          src={
                            require("../../assets/images/structure/applied-gray.svg")
                              .default
                          }
                          alt=""
                          className="applied mr-20"
                        />
                        <div
                          className={ countChatData ? "positiontabs d-flex align-items-center d-flex align-items-center" : "positiontabs d-flex align-items-center d-flex align-items-center disabled-positiontabs"}
                          >
                          <p className="fontsize-16 grayb5 mb-0 fontfamily-semibold d-flex align-items-center w-100">
                            Applications Recieved{" "}
                            <span className={(countChatData ? "fontfamily-bold posNo ml-auto orangefc" : "grayb5 posNo ml-auto orangefc")} >
                              {countChatData}{" "}
                              <img
                                src="images/structure/arrow-prev.svg"
                                alt=""
                                className="arrow-prev ml-3"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-45 mt-xs-0 profile-position-box">
                      <button
                        className="btn fill-gray width200"
                        data-bs-toggle="modal"
                        data-bs-target=".postNew"
                      >
                        Post New Position
                      </button>
                      <p className="fontsize-10 grayb5 mb-0 fontfamily-regular mt-20 mt-xs-0">
                        *After Your verification, you will unlock these
                        functions
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-md-12 col-sm-12 col-12 profile-right-col language-coloum">
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".languageDialog"
                    >
                      <h6 className="fontsize-16 fontfamily-semibold black mb-20">
                        language
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0 text-height-12">
                        {employerData?.language}
                      </p>
                    </div>
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".citiesDialog"
                    >
                      <h6 className="fontsize-16 fontfamily-semibold black mb-20">
                        Location
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0 text-height-12">
                        {employerData?.city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmployerProfile;
