import { useTranslation } from "react-i18next";

const VerificationCodeModal = () => {
    const { t } = useTranslation();

    const closeModal = () => {
        window.location.reload();
      }

    return (
      <div
        className="modal submittedSuccessModal modalwidth428 show"
        tabIndex={-1}
        aria-labelledby="submittedModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalBoxSubmit">
            <h4
              className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center"
              style={{ color: "#31D0AA" }}
            >
              <i className="mr-10">Confirmed</i>!
            </h4>
            <div className="" style={{ borderBottom: 0 }}>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <img
                  src={
                    require("../../assets/images/structure/close-circle-outline.svg")
                      .default
                  }
                  alt=""
                />
              </button>
            </div>
            <div className="modal-body">
              <p className="mt-25 text-center" style={{ color: "#6D6D6D" }}>
              {t("Congrats! Your submisson is confirmed!")}
              </p>
            </div>
            <div className="mb-40 text-center" >
              <button className="btn width200" onClick={closeModal}>Back</button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default VerificationCodeModal;
  