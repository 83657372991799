import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeEmployerCollectionApiCall, getEmployerCollectionApiCall, getCountSavedTalentsApiCall } from "../../redux/actions";
import { GET_ADDRESS, GET_CURRENT_ROLE } from "../../shared/Storage";

const SavedTalentsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employerCollectionData } = useSelector(
    (item: any) => item?.discoverReducer
  );

  const onPressFavorite = async (item: any) => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      user_id: item?.id,
    };
    await removeEmployerCollectionApiCall(body)(dispatch);
    await getEmployerCollectionApiCall(address)(dispatch);
    await getCountSavedTalentsApiCall(address)(dispatch);
  };

  const fetchData = async () => {
    const address = await GET_ADDRESS();
    const role = await GET_CURRENT_ROLE();
    if(role === '0') {
      getEmployerCollectionApiCall(address)(dispatch);
    }
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="modal fade Savedposition modalwidth828"
      tabIndex={-1}
      aria-labelledby="SavedpositionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body p-xs-0">
            <div className="positionItemBox saved-template saved-talents">
              <h5 className="modal-title black0d fontfamily-semibold mb-30 fontsize-16 text-center">
                Saved Talents
              </h5>
              <div className="container">
                <div className="row">
                  {employerCollectionData?.map((item: any, index: number) => (
                    <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="productListBlock">
                        <div
                          className="talent-card"
                          data-bs-toggle="modal"
                          data-bs-target=".employeetalktoHR"
                        >
                          <div className="product-row1 d-flex align-items-center">
                            <img
                              src={require("../../assets/images/products/portrait_default.png").default}
                              className="prodImage"
                              alt=""
                            />
                            <div className="pl-15">
                              <p className="fontsize-16 black fontfamily-medium mb-0">
                                {item?.name}
                              </p>
                              <p className="fontsize-14 fontfamily-medium mb-0">
                                {item?.desiredPosition}
                              </p>
                              <span>
                                <p className="gray80 fontfamily-medium mb-2 fontsize-12">
                                  <img
                                    src={
                                      require("../../assets/images/structure/location.svg")
                                        .default
                                    }
                                    alt=""
                                    className="locationIcon mr-10"
                                  />
                                  {item?.city.split(",")[0]}
                                </p>
                              </span>
                            </div>
                            <div className="likebox ml-auto" onClick={() => onPressFavorite(item)} >
                              <img
                                src={
                                  require("../../assets/images/structure/save-pink.svg")
                                    .default
                                }
                                alt=""
                               //className="saveIcon1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row product">
                          <div className="box">
                            <div className="row">
                              <div className="saved-template-years">
                                <img
                                  src={require("../../assets/images/structure/university.png").default}
                                  alt=""
                                  className="mb-2"
                                  height="16px"
                                />
                                <p className="fontsize-12 fontfamily-semibold  fontfamily-Poppins mb-0">
                                  University of Pennsylvania
                                </p>
                              </div>

                              <div className="row">
                                <div className="saved-template-years">
                                  <img
                                    src={
                                      require("../../assets/images/structure/breifcase.svg")
                                        .default
                                    }
                                    alt=""
                                    className="mb-2"
                                    height="15px"
                                  />
                                  <p className="fontsize-12 fontfamily-semibold fontfamily-Poppins mb-0">
                                    8 Years
                                  </p>
                                </div>

                                <p className="avtarText">
                                  {item?.bio}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedTalentsModal;
