import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CitySelectionModal from "../../components/Modals/CitySelectionModal";
import KeywordsModal from "../../components/Modals/KeywordsModal";
import LoadingOverlay from "../../components/Modals/LoadingOverlay";
import TalentDetailsModal from "../../components/Modals/TalentDetailsModal";
import WorkExpRangeModal from "../../components/Modals/WorkExpRangeModal";
import SearchResultItem from "../../components/SearchResultItem/SearchResultItem";
import {
  getEmployeesListApiCall,
  saveEmployeeCollectionApiCall,
  getCityArrayApiCall,
} from "../../redux/actions";
import { BACKGROUND_COLOR } from "../../shared/Colors";
import {
  GET_ADDRESS,
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_ROLE,
  GET_POSITIONS_LIST,
} from "../../shared/Storage";

const DiscoverEmployer = () => {
  const dispatch = useDispatch();

  const [talent, setTalent] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [positionsList, setPositionsList] = useState<string[]>();
  const [searchResult, setSearchResult] = useState<string[]>();

  const [recommended, setRecommended] = useState(0);
  const [keywords, setKeyWords] = useState<string[]>([]);
  const [experienceType, setExperienceType] = useState(0);
  const [minYears, setMinYears] = useState("");
  const [maxYears, setMaxYears] = useState("");
  const [title, setTitle] = useState("");
  const [titleTemp, setTitleTemp] = useState("");

  const [fetching, setFetching] = useState(false);

  const { city, experienceYears } = useSelector(
    (item: any) => item?.userReducer
  );

  const { employeeListData } = useSelector(
    (item: any) => item?.discoverReducer
  );

  const checkProfileImageSrc = (item: any) => {
    return item?.image
      ? item?.image
      : require("../../assets/images/products/portrait_default.png").default;
  };

  const checkIsTalentSaved = (item: any) => {
    return item?.collected
      ? require("../../assets/images/structure/save-pink.svg").default
      : require("../../assets/images/structure/save.svg").default;
  };

  const checkExperineceRange = () => {
    return experienceType === 0 ? "Any Years" : `${minYears}-${maxYears} Years`;
  };

  const onPressFavorite = async (item: any) => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      user_id: item.id,
    };
    await saveEmployeeCollectionApiCall(body)(dispatch);
    await getEmployeesListApiCall(address, {
      recommended,
      title,
      city,
      minYears,
      maxYears,
      keywords,
    })(dispatch);
  };

  const handleTitleTemp = (val: any) => {
    setTitleTemp(val);
    searchForPosition(val);
  }

  const onConfirmHandler = (item: string) => {
    setTitleTemp(item);
    setTitle(item);
    searchForPosition("");
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setTitle(titleTemp);
    }
  };

  const searchForPosition = (searchPhrase: string) => {
    if (searchPhrase.length > 0) {
      const searchText = searchPhrase.toLowerCase();
      let filtredPositions: string[] = [];
      positionsList?.map((item: string) => {
        const currentItem = item.toLowerCase();
        if (currentItem === searchText || currentItem.startsWith(searchText)) {
          filtredPositions.push(item);
        }
      });
      setSearchResult(filtredPositions);
    } else {
      setSearchResult(positionsList);
    }
  };

  const fetchData = async () => {
    setFetching(true);
    const role = await GET_CURRENT_ROLE();
    const language = await GET_CURRENT_LANGUAGE();
    if (role === "0") {
      const address = await GET_ADDRESS();
      const positionsList = await GET_POSITIONS_LIST();
      setPositionsList(positionsList?.split(","));
      await getEmployeesListApiCall(address, {
        recommended,
        title,
        city,
        minYears,
        maxYears,
        keywords,
      })(dispatch);
      await getCityArrayApiCall(language)(dispatch);
      setFetching(false);
    }
  };

  const fetchDataWithFilter = async () => {
    setFetching(true);
    const address = await GET_ADDRESS();
    await getEmployeesListApiCall(address, {
      recommended,
      title,
      city,
      minYears,
      maxYears,
      keywords,
    })(dispatch);
    setFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchDataWithFilter();
  }, [recommended, city, minYears, maxYears, keywords, title]);


  return (
    <>
      <div className="main-wrapper discover-employer-wrapper">
        {fetching && <LoadingOverlay />}
        <CitySelectionModal />
        <WorkExpRangeModal
          setExperienceType={setExperienceType}
          minYears={minYears}
          maxYears={maxYears}
          setMinYears={setMinYears}
          setMaxYears={setMaxYears}
        />
        <KeywordsModal setKeyWords={setKeyWords} />
        <TalentDetailsModal details={talent} />
        <section className="employee-discover-sec1">
          <div className="container filterContainer">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-2">
                <div className="d-flex flex-wrap discover-list">
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".citiesDialog"
                    >
                      {city.length > 0 ? city : "Location"}
                    </p>
                  </div>
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".workExperenceDialog"
                    >
                      {checkExperineceRange()}
                    </p>
                  </div>
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".keywordsDialog"
                      style={{ color: keywords?.length > 0 ? "#704EF8" : "" }}
                    >
                      Keywords
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-3">
                <div className="d-flex flex-wrap discover-list-right">
                  <div
                    className="filtercheckbox"
                    onClick={() => setRecommended(0)}
                  >
                    <input type="radio" name="recnew" />
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      style={{ color: recommended === 0 ? "#704EF8" : "" }}
                    >
                      Recommend
                    </p>
                  </div>
                  <div
                    className="filtercheckbox"
                    onClick={() => setRecommended(1)}
                  >
                    <input type="radio" name="recnew" />
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      style={{ color: recommended === 1 ? "#704EF8" : "" }}
                    >
                      Newest
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-1">
                <div className="form-group form-input searchInput">
                  <input
                    type="text"
                    placeholder="Enter a position name, e.g. Software Engineer"
                    className="form-control"
                    onChange={(evt) => handleTitleTemp(evt.target.value)}
                    value={titleTemp}
                    onKeyDown={handleKeyDown}
                  />
                  <img
                    src={
                      require("../../assets/images/structure/search.svg")
                        .default
                    }
                    alt=""
                    className="searchIcon"
                  />
                  {titleTemp.length !== 0 && (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      backgroundColor: BACKGROUND_COLOR,
                      opacity: 0.9,
                      paddingLeft: 48,
                    }}
                  >
                    {searchResult?.map((item, index) => (
                      <SearchResultItem
                        key={index}
                        onPress={() => onConfirmHandler(item)}
                        text={title}
                        result={item}
                      />
                    ))}
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {employeeListData?.map((item: any, index: number) => (
                <div key={index} className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="productListBlock"
                    onClick={() => setTalent(item)}
                    data-bs-toggle="modal"
                    data-bs-target=".employeetalktoHR"
                  >
                    <div className="talent-card">
                      <div className="product-row1 d-flex align-items-center">
                        <img
                          src={checkProfileImageSrc(item)}
                          className="prodImage"
                          alt=""
                        />
                        <div
                          className="pl-15"
                        >
                          <p className="fontsize-16 black fontfamily-medium mb-0">
                            {item?.name}
                          </p>
                          <p className="fontsize-14 fontfamily-medium mb-0">
                            {item?.desiredPosition}
                          </p>
                          <span>
                            <p className="gray80 fontfamily-medium mb-2 fontsize-12">
                              <img
                                src={
                                  require("../../assets/images/structure/location.svg")
                                    .default
                                }
                                alt=""
                                className="locationIcon mr-10"
                              />
                              {item?.city.split(",")[0]}
                            </p>
                          </span>
                        </div>
                        <div
                          className="likebox ml-auto"
                          onClick={() => onPressFavorite(item)}
                        >
                          <img
                            src={checkIsTalentSaved(item)}
                            alt=""
                            //className="saveIcon1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row product">
                      <div className="box">
                        <div className="row">
                          <div className="saved-template-years">
                            <img
                              src={require("../../assets/images/structure/university.png").default}
                              alt=""
                              className="mb-2"
                              height="16px"
                            />
                            <p className="fontsize-12 fontfamily-semibold  fontfamily-Poppins mb-0">
                              {item?.highestGraduateSchool}
                            </p>
                          </div>

                          <div className="row">
                            <div className="saved-template-years">
                              <img
                                src={
                                  require("../../assets/images/structure/breifcase.svg")
                                    .default
                                }
                                alt=""
                                className="mb-2"
                                height="15px"
                              />
                              <p className="fontsize-12 fontfamily-semibold fontfamily-Poppins mb-0">
                                {+(item?.workMonths / 12).toFixed(1) + ' years'}
                              </p>
                            </div>
                            <p className="avtarText">{item?.bio}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default DiscoverEmployer;
