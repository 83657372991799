import {
  SAVE_DATA_REDUX,
  SKILL_LIST_DATA_REDUX,
  POSITION_LIST_DATA_REDUX,
  COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
  EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
  EMPLOYEE_WORK_LIST_DATA_REDUX,
} from '../types/types';
import {
  SERVER_REQUEST,
  GET_SKILLS_ARRAY_API,
  GET_POSITIONS_ARRAY_API,
  SAVE_EMPLOYEE_SKILLS_API,
  SAVE_EMPLOYEE_DESIRED_POSITION,
  COUNT_EMPLOYEE_COLLECTION_API,
  ADD_EDUCATION_API,
  GET_EDUCATION_LIST_API,
  REMOVE_EMPLOYEE_EDUCATION_API,
  ADD_WORK_API,
  GET_WORK_LIST_API,
  REMOVE_EMPLOYEE_WORK_API,
} from '../../shared/Backend';

export const getSkillsArrayApiCall = language => async dispatch => {
  dispatch({type: SKILL_LIST_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_SKILLS_ARRAY_API}?language=${language}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: SKILL_LIST_DATA_REDUX,
        data: response.data,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: SKILL_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: SKILL_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const postEmployeeSkillsApiCall =
  (body, navigation) => async dispatch => {
    dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
    try {
      const response = await SERVER_REQUEST(
        body,
        SAVE_EMPLOYEE_SKILLS_API,
        'POST',
      );
      if (response.message === 'ok') {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
       
        //navigation.goBack();
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const getPositionsArrayApiCall = language => async dispatch => {
  dispatch({type: POSITION_LIST_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_POSITIONS_ARRAY_API}?language=${language}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: POSITION_LIST_DATA_REDUX,
        data: response.data,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: POSITION_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: POSITION_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const postEmployeeDesiredPositionApiCall =
  (body, navigation) => async dispatch => {
    dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
    try {
      const response = await SERVER_REQUEST(
        body,
        SAVE_EMPLOYEE_DESIRED_POSITION,
        'POST',
      );
      if (response.message === 'ok') {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: null,
        });
        navigation.goBack();
      } else {
        dispatch({
          type: SAVE_DATA_REDUX,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: error.Message,
      });
    }
  };

export const getCountSavedPositionsApiCall = address => async dispatch => {
  dispatch({
    type: COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
    loader: false,
    message: null,
  });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${COUNT_EMPLOYEE_COLLECTION_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
        data: response.count,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const postEducationApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(body, ADD_EDUCATION_API, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
      
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
   
  }
};

export const postWorkApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(body, ADD_WORK_API, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getEmployeeEducationListApiCall = address => async dispatch => {
  dispatch({
    type: EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
    loader: true,
    message: null,
  });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_EDUCATION_LIST_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
        data: response.educations,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getEmployeeWorkListApiCall = address => async dispatch => {
  dispatch({
    type: EMPLOYEE_WORK_LIST_DATA_REDUX,
    loader: true,
    message: null,
  });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_WORK_LIST_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: EMPLOYEE_WORK_LIST_DATA_REDUX,
        data: response.works,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: EMPLOYEE_WORK_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: EMPLOYEE_WORK_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const removeEducationApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      body,
      REMOVE_EMPLOYEE_EDUCATION_API,
      'POST',
    );
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      //navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const removeWorkApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      body,
      REMOVE_EMPLOYEE_WORK_API,
      'POST',
    );
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};
