import {combineReducers} from 'redux';
import authReducer from './AuthenticationReducer';
import UserReducer from "./UserReducer";
import EmployerProfileReducer from './EmployerProfileReducer';
import EmployeeProfileReducer from './EmployeeProfileReducer';
import DiscoverReducer from './DiscoverReducer'


export default combineReducers({
  authData: authReducer,
  userReducer: UserReducer,
  employerProfileReducer: EmployerProfileReducer,
  employeeProfileReducer: EmployeeProfileReducer,
  discoverReducer: DiscoverReducer,
});
