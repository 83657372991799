import {
  SAVE_DATA_REDUX,
  POSITIONS_LIST_DATA_REDUX,
  POSITION_DATA_REDUX,
  COUNT_POSITIONS_DATA_REDUX,
  COUNT_EMPLOYER_COLLECTION_DATA_REDUX,
} from '../types/types';
import {
  SERVER_REQUEST,
  ADD_POSITION_API,
  EDIT_POSITION_API,
  GET_POSITIONS_BY_ADDRESS_API,
  GET_POSITION_BY_ID_API,
  REMOVE_POSITION_API,
  COUNT_POSITIONS_API,
  COUNT_EMPLOYER_COLLECTION_API,
} from '../../shared/Backend';

export const postNewPositionApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(body, ADD_POSITION_API, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const editPositionApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(body, EDIT_POSITION_API, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getPositionByIdApiCall = id => async dispatch => {
  dispatch({type: POSITION_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_POSITION_BY_ID_API}?position_id=${id}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: POSITION_DATA_REDUX,
        data: {...response.position, employer: {...response?.employer}},
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: POSITION_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: POSITION_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getPositionsListApiCall = address => async dispatch => {
  dispatch({type: POSITIONS_LIST_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_POSITIONS_BY_ADDRESS_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: POSITIONS_LIST_DATA_REDUX,
        data: response.positions,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: POSITIONS_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: POSITIONS_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const removePositionApiCall = (body, navigation) => async dispatch => {
  dispatch({type: SAVE_DATA_REDUX, loader: true, message: null});
  try {
    const response = await SERVER_REQUEST(body, REMOVE_POSITION_API, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      //navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getCountPositionsApiCall = address => async dispatch => {
  dispatch({type: COUNT_POSITIONS_DATA_REDUX, loader: false, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${COUNT_POSITIONS_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: COUNT_POSITIONS_DATA_REDUX,
        data: response.count,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: COUNT_POSITIONS_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: COUNT_POSITIONS_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getCountSavedTalentsApiCall = address => async dispatch => {
  dispatch({type: COUNT_EMPLOYER_COLLECTION_DATA_REDUX, loader: false, message: null});
  try {
    const response = await SERVER_REQUEST(
      null,
      `${COUNT_EMPLOYER_COLLECTION_API}?address=${address}`,
      'GET',
    );
    if (response.message === 'ok') {
      dispatch({
        type: COUNT_EMPLOYER_COLLECTION_DATA_REDUX,
        data: response.count,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: COUNT_EMPLOYER_COLLECTION_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log('error', error);
    dispatch({
      type: COUNT_EMPLOYER_COLLECTION_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};
