import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GET_ADDRESS, SAVE_ADDRESS } from "../../shared/Storage";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { connectors } from "../../shared/Connectors";
import { getUserDataApiCall } from "../../redux/actions";

const LogInSignUpModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activate, active, account } = useWeb3React();

  const onPressMetamask = async () => {
    activate(connectors.injected).then(() => {});
  };

  const onPressWalletConnect = () => {
    activate(connectors.walletConnect);
  };

  useEffect(() => {
    (async () => {
      const adr = await GET_ADDRESS();
      if (account && !adr) {
        SAVE_ADDRESS(account);
        navigate("/enable");
      }
    })();
  }, [account, active]);

  return (
    <div
      className="modal fade login-modal home-modal"
      id="loginDialog"
      tabIndex={-1}
      aria-labelledby="loginDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pt-35 d-flex justify-content-center">
            <div className="text-center">
              <h5 className="modal-title blue70 fontfamily-blackitalic mb-20 fontsize-22">
                Log in & Sign Up
              </h5>
              <p className="fontsize-16 black fontfamily-semibold mb-0">
                Connect Your Web3.0 Wallet
              </p>
            </div>
            <button
              type="button"
              className="btn-close connectmodal-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              //onClick={() => handleModal(false)}
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="loginwalletbox">
              <div className="bgwhite pt-20 pb-20 pl-25 pr-25 border-radius16 login-box">
                <div
                  onClick={onPressMetamask}
                  data-bs-dismiss="modal"
                  className="d-flex align-items-center justify-content-center loginwalletbtn"
                >
                  <img
                    src={
                      require("../../assets/images/structure/wallet1.svg")
                        .default
                    }
                    alt=""
                  />{" "}
                  <p className="fontsize-16 black fontfamily-semibold ml-auto mb-0">
                    Metamask
                  </p>
                </div>
                {/* <div className="divider mt-20 mb-20"></div>
                <div className="d-flex align-items-center justify-content-center loginwalletbtn">
                  <img
                    src={
                      require("../../assets/images/structure/tp_icon.svg")
                        .default
                    }
                    alt=""
                  />{" "}
                  <p className="fontsize-16 black fontfamily-semibold ml-auto mb-0">
                    TokenPocket
                  </p>
                </div> */}
                <div className="divider mt-20 mb-20"></div>
                <div
                  onClick={onPressWalletConnect}
                  className="d-flex align-items-center justify-content-center loginwalletbtn"
                >
                  <img
                    src={
                      require("../../assets/images/structure/wallet-connect.svg")
                        .default
                    }
                    alt=""
                  />{" "}
                  <p className="fontsize-16 black fontfamily-semibold ml-auto mb-0">
                    WalletConnect
                  </p>
                </div>
              </div>
              <p className="fontsize-12 gray5a fontfamily-regular mb-0 mt-35 text-center mt-sm-0">
                By connecting a wallet, you agree to 0xtalents{" "}
                <span className="blue70">Terms of Service</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInSignUpModal;
