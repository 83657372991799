import { useState } from "react";
import MessageComposer from "./components/MessageComposer";
import MessagesList from "./components/MessagesList";

const Conversations = ({
  recipientWalletAddr,
  setRecipientWalletAddr,
}: any) => {
  const [recipientInfo, setRecipientInfo] = useState();

  return (
    <>
      <div className="main-wrapper bggrayfa">
        <section className="chat-sec">
          <div className="container">
            <div className="row">
              <div className="col-xl-11 col-lg-11 col-md-12 col-md-12 col-sm-12 col-12 mx-auto" style={{position: 'absolute', height:"calc(100% - 120px)"}}>
                <div className="row" style={{height:"100%"}}>
                  <MessagesList
                    recipientWalletAddr={recipientWalletAddr}
                    setRecipientWalletAddr={setRecipientWalletAddr}
                    setRecipientInfo={setRecipientInfo}
                  />
                  {recipientWalletAddr && (
                    <MessageComposer
                      onClose={() => setRecipientWalletAddr(null)}
                      peerAddress={recipientWalletAddr}
                      recipientInfo={recipientInfo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Conversations;
