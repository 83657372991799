import { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import {
  getEmployeeDataApiCall,
  getEmployerDataApiCall,
  uploadProfileImageApiCall,
} from "../../redux/actions";
import { GET_ADDRESS, GET_CURRENT_ROLE } from "../../shared/Storage";

const UploadImagePreviewModal = ({ imgSrc, imgFile, setImgSrc }: any) => {
  const dispatch = useDispatch();
  const imgRef = useRef<HTMLImageElement>(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [blobFile, setBlobFile] = useState<any>();

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const imageCropComplete = (crop: any) => {
    userCrop(crop);
  };

  const userCrop = async (crop: any) => {
    if (imgFile && crop.width && crop.height) {
      await getCroppedImage(imgRef.current, crop, imgFile.name);
    }
  };

  const getCroppedImage = (image: any, crop: any, fileName: any) => {
    const imageCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    imageCanvas.width = crop.width;
    imageCanvas.height = crop.height;
    const imgCx = imageCanvas.getContext("2d");
    imgCx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((reject, resolve) => {
      imageCanvas.toBlob((blob: any) => {
        if (!blob) {
          reject(new Error("the image canvas is empty"));
          return;
        }
        blob.name = fileName;
        let imageURL;
        window.URL.revokeObjectURL(image.url);
        imageURL = window.URL.createObjectURL(blob);
        resolve(imageURL);
        setBlobFile(blob);
      }, imgFile.type);
    });
  };

  const uploadImage = async () => {
    const newImage = new File([blobFile], blobFile?.name, {
      type: blobFile?.type,
    });
    const role = await GET_CURRENT_ROLE();
    const address = await GET_ADDRESS();
    let formdata = new FormData();
    formdata.append("address", address);
    formdata.append("file", newImage);

    await uploadProfileImageApiCall(formdata)(dispatch);
    if (role === "1") {
      await getEmployeeDataApiCall(address)(dispatch);
    } else {
      await getEmployerDataApiCall(address)(dispatch);
    }
    setIsImageSelected(false);
  };

  useEffect(() => {
    if (imgSrc.length > 0) {
      setIsImageSelected(true);
    }
  }, [imgSrc]);

  return (
    <div
      className={
        "modal fade" +
        (isImageSelected ? " show d-block " : " ") +
        "imagePreviewDialog modalwidth444"
      }
      tabIndex={-1}
      aria-labelledby="imagePreviewDialog    Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setIsImageSelected(false);
                setImgSrc("");
                setCrop(undefined);
              }}
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div
              className="jobtypeBox"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={imageCropComplete}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
            </div>
            <div className="mt-30 text-center" data-bs-dismiss="modal">
              <button className="btn width200" onClick={uploadImage}>
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImagePreviewModal;
