import {
  CHANGE_USER_CITY,
  CHANGE_EXPERIENCE_YEARS,
  SAVE_DATA_REDUX,
  USER_DATA_REDUX,
  EMPLOYEE_DATA_REDUX,
  EMPLOYER_DATA_REDUX,
  CITY_LIST_DATA_REDUX,
  USERS_DATA_ARRAY_REDUX,
  COUNT_CHAT_REDUX,
} from "../types/types";

const initialState = {
  city: "",
  experienceYears: "Any Years",
  loading: false,
  errorMessage: null,
  userData: null,
  userDataArray: null,
  employerData: null,
  employeeData: null,
  cityListData: null,
  countChatData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        errorMessage: action.message,
      };
    case USER_DATA_REDUX:
      return {
        ...state,
        userData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case USERS_DATA_ARRAY_REDUX:
      return {
        ...state,
        userDataArray: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case EMPLOYEE_DATA_REDUX:
      return {
        ...state,
        employeeData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case EMPLOYER_DATA_REDUX:
      return {
        ...state,
        employerData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case CITY_LIST_DATA_REDUX:
      return {
        ...state,
        cityListData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case CHANGE_USER_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case CHANGE_EXPERIENCE_YEARS:
      return {
        ...state,
        experienceYears: action.payload,
      };
    case COUNT_CHAT_REDUX:
      return {
        ...state,
        countChatData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
