export const SAVE_TOKEN = (value) => {
  localStorage.setItem("token", value);
};

export const GET_TOKEN = async () => {
  const value = localStorage.getItem("token");
  return value || "";
};

export const REMOVE_TOKEN = async () => {
  localStorage.removeItem("token");
};

export const SAVE_ADDRESS = (value) => {
  localStorage.setItem("address", value);
};

export const GET_ADDRESS = async () => {
  const value = localStorage.getItem("address");
  return value || "";
};

export const REMOVE_ADDRESS = async () => {
  localStorage.removeItem("address");
};

export const GET_CURRENT_LANGUAGE = async () => {
  const value = localStorage.getItem("CURRENT_LANGUAGE");
  return value || "";
};

export const SET_CURRENT_LANGUAGE = async (value) => {
  localStorage.setItem("CURRENT_LANGUAGE", value);
};

export const REMOVE_CURRENT_LANGUAGE = async () => {
  localStorage.removeItem("CURRENT_LANGUAGE");
};

export const GET_CURRENT_ROLE = async () => {
  return localStorage.getItem("CURRENT_ROLE");
};

export const SET_CURRENT_ROLE = async (value) => {
  localStorage.setItem("CURRENT_ROLE", value);
};

export const REMOVE_CURRENT_ROLE = async () => {
  localStorage.removeItem("CURRENT_ROLE");
};

export const GET_USER = async () => {
  return localStorage.getItem("USER");
};

export const SET_USER = async (value) => {
  localStorage.setItem("USER", value);
};

export const REMOVE_USER = async () => {
  localStorage.removeItem("USER");
};

export const GET_POSITIONS_LIST = async () => {
  return localStorage.getItem("POSITIONS_LIST");
};

export const SET_POSITIONS_LIST = async (value) => {
  localStorage.setItem("POSITIONS_LIST", value);
};

export const REMOVE_POSITIONS_LIST = async () => {
  localStorage.removeItem("POSITIONS_LIST");
};

export const GET_SKILLS_LIST = async () => {
  return localStorage.getItem("SKILLS_LIST");
};

export const SET_SKILLS_LIST = async (value) => {
  localStorage.setItem("SKILLS_LIST ", value);
};

export const REMOVE_SKILLS_LIST = async () => {
  localStorage.removeItem("SKILLS_LIST");
};

export const GET_CITIES_LIST = async () => {
  return localStorage.getItem("CITIES_LIST");
};

export const SET_CITIES_LIST = async (value) => {
  localStorage.setItem("CITIES_LIST ", value);
};

export const REMOVE_CITIES_LIST = async () => {
  localStorage.removeItem("CITIES_LIST");
};

export const SAVE_OAUTH_TOKEN = (value) => {
  localStorage.setItem("isOauthVerified", value);
};

export const GET_OAUTH_TOKEN = async () => {
  const value = localStorage.getItem("isOauthVerified");
  return value || "";
};

export const REMOVE_OAUTH_TOKEN = (value) => {
  localStorage.removeItem("isOauthVerified");
};