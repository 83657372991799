import { useEffect, useState } from "react";
import { SAVE_OAUTH_TOKEN } from "../../shared/Storage";

const OauthNotify = () => {

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const appName = queryParameters.get("appName");

    SAVE_OAUTH_TOKEN(`${appName}_isOauthVerified`);
    window.dispatchEvent(new Event("storage"));
    window.close();
  }, []);

  return (
    <>You can close this page safely.
    </>
  );
};

export default OauthNotify;
