import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import { GET_ADDRESS, GET_CURRENT_ROLE, GET_USER } from "../../shared/Storage";


const Navbar = () => {

  const { active, account } = useWeb3React();

  const [isLoggedIn, setisLoggedIn] = useState<boolean>();
  const [role, setRole] = useState<string | null>();
  const [address, setAddress] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    (async () => {
      const user = await GET_USER();
      setRole(await GET_CURRENT_ROLE());
      setAddress(await GET_ADDRESS());
      setisLoggedIn(user ? true : false);
    })();
  }, [account, location.pathname]);

  const getAbbr = () => {
    if(address) {
      const len = address.length;
      if (len < 10) return len;
      return address?.substr(0, 6) + "..." + address?.substr(len - 4, len);
    }
  };

  return (
    <div className="relative header header-before-login">
      <nav className="navbar navbar-expand-l">
        <div className="container d-none d-md-flex nav-container">
          <div className="header-left">
            <div
              className="navbar-brand logoname pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={require("../../assets/images/logo/logo.png").default}
                className="logo-image"
                alt=""
              />
            </div>
            {location.pathname !== "/create" && (
                <ul className="mr-auto header-menu">
                  {(isLoggedIn && role == "1" || !isLoggedIn) && (<li className="nav-item">
                    <div
                      className="nav-link"
                      onClick={() => navigate("/jobs")}
                    >
                      Jobs
                    </div>
                  </li>)}
                  {(isLoggedIn && role == "0" || !isLoggedIn) && (<li className="nav-item">
                    <div
                      className="nav-link"
                      onClick={() => navigate("/talents")}
                    >
                      Talents
                    </div>
                  </li>)}
                  {isLoggedIn && (<li className="nav-item">
                    <div
                      className="nav-link"
                      data-link="/chat"
                      onClick={() => navigate("/chat")}
                    >
                      Messages
                    </div>
                  </li>)}
                  {isLoggedIn && (<li className="nav-item">
                    <div
                      className="nav-link"
                      onClick={() => navigate("/profile")}
                    >
                      Profile
                    </div>
                  </li>)}
                  <li className="nav-item">
                    <div
                      className="nav-link"
                      onClick={() => navigate("/download")}
                    >
                      Download
                    </div>
                  </li>
                </ul>
            )}
          </div>
          <div className="header-right-menu ">
            <ul className="navbar-nav ml-auto hdr-getstartedbtn-block">
              <li className="nav-item">
                {address ? (
                  <button
                    className="nav-link connectWalletbtn"
                    data-bs-toggle="modal"
                    data-bs-target=".logoutDialog"
                  >
                    <img
                      src={
                        require("../../assets/images/structure/connect-wallet.svg")
                          .default
                      }
                      alt=""
                    />{" "}
                    {getAbbr()}
                  </button>
                ) : (
                  <button
                    className="nav-link connectWalletbtn"
                    data-bs-toggle="modal"
                    data-bs-target="#loginDialog"
                  >
                    <img
                      src={
                        require("../../assets/images/structure/connect-wallet.svg")
                          .default
                      }
                      alt=""
                    />{" "}
                    Connect Wallet
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="container d-md-none d-md-flex">
          <ul className="navbar-nav mr-auto mb-pagelist" id="mbPageList">
            <li className="nav-item">
              <div
                className={(location.pathname == '/' ? "nav-link active" : "nav-link")}
                onClick={() => navigate("/")}
                >
                Home
              </div>
            </li>
            {(isLoggedIn && role == "1" || !isLoggedIn) && (
              <li className="nav-item">
                <div
                  className={(location.pathname == '/jobs' ? "nav-link active" : "nav-link")}
                  onClick={() => navigate("/jobs")}
                  >
                  Jobs
                </div>
              </li>)}
            {(isLoggedIn && role == "0" || !isLoggedIn) && (
              <li className="nav-item">
                <div
                  className={(location.pathname == '/talents' ? "nav-link active" : "nav-link")}
                  onClick={() => navigate("/talents")}
                  >
                  Talents
                </div>
              </li>)}
              {isLoggedIn && <li className="nav-item">
                <div
                  className={(location.pathname == '/download' ? "nav-link active" : "nav-link")}
                  onClick={() => navigate("/chat")}
                  >
                  Messages
                </div>
              </li>}
            {isLoggedIn && (
              <li className="nav-item">
                <div
                  className={(location.pathname == '/profile' ? "nav-link active" : "nav-link")}
                  onClick={() => navigate("/profile")}
                  >
                  Profile
                </div>
              </li>)}
              {!isLoggedIn && (<li className="nav-item">
                <div
                  className={(location.pathname == '/download' ? "nav-link active" : "nav-link")}
                  onClick={() => navigate("/download")}
                  >
                  Download
                </div>
              </li>)}
              {address ? (<li className="nav-item">
                <div
                  className={(location.pathname == '/account' ? "nav-link active" : "nav-link")}
                  data-bs-toggle="modal"
                  data-bs-target=".logoutDialog">
                  Account
                </div>
              </li>) : 
              (<li className="nav-item">
                <div
                  className="nav-link"
                  data-link="/connect"
                  data-bs-toggle="modal"
                  data-bs-target="#loginDialog">
                  Connect
                </div>
              </li>)
            }
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
