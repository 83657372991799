import { FC, Suspense, useEffect } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import "./App.css";
import "./languages/i18n";
import Router from "./pages/router";
import { useAppStore } from "./store/app";


const App: FC = () => {
  const { library } = useWeb3React();

  const setAddress = useAppStore((state) => state.setAddress);
  const setSigner = useAppStore((state) => state.setSigner);
  const signer = useAppStore((state) => state.signer);
  const client = useAppStore((state) => state.client);

  useEffect(() => {
    (async () => {
      if (library) {
        const provider = new ethers.providers.Web3Provider(library.provider);
        const newSigner = provider.getSigner();
        setSigner(newSigner);
        setAddress(await newSigner.getAddress());
      }
    })()
  }, [library]);

  return (
    <Suspense fallback={null}>
      <Router />
    </Suspense>
  );
};

export default App;
