import {
  SAVE_DATA_REDUX,
  USER_DATA_REDUX,
  EMPLOYEE_DATA_REDUX,
  EMPLOYER_DATA_REDUX,
  CITY_LIST_DATA_REDUX,
  USERS_DATA_ARRAY_REDUX,
  COUNT_CHAT_REDUX,
  EXTENDED_DATA_REDUX,
  EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX,
  EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX
} from "../types/types";
import {
  SERVER_REQUEST,
  GET_USER_DATA_API,
  GET_EXTENDED_USER_API,
  GET_CITY_ARRAY,
  UPLOAD_PROFILE_IMAGE_API,
  GET_USERS_ARRAY_API,
  START_OF_AN_CHAT_API,
  COUNT_OF_CHAT_API,
  UPDATE_USER_CITY_API,
  UPDATE_USER_LANGUAGE_API,
  VERIFY_EMAIL_SEND,
  VERIFY_EMAIL_CONFIRM
} from "../../shared/Backend";
import { SET_CURRENT_ROLE, SET_USER } from "../../shared/Storage";

export const getUserDataApiCall = (address, navigate) => async (dispatch) => {
  dispatch({ type: USER_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_USER_DATA_API}?address=${address}`,
      "GET"
    );
    if (response.message === "ok") {
      dispatch({
        type: USER_DATA_REDUX,
        data: response.user,
        loader: false,
        message: null,
      });
      if (navigate) {
        SET_CURRENT_ROLE(response.user.userType);
        SET_USER(JSON.stringify(response));
        navigate("/profile", { replace: true });
      }
    } else {
      dispatch({
        type: USER_DATA_REDUX,
        loader: false,
        message: response.error,
      });
      if (navigate) {
        navigate("/create");
      }
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: USER_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getUsersDataArrayApiCall = (addresses) => async (dispatch) => {
  dispatch({ type: USERS_DATA_ARRAY_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_USERS_ARRAY_API}?addresses=${addresses}`,
      "GET"
    );
    if (response.message === "ok") {
      dispatch({
        type: USERS_DATA_ARRAY_REDUX,
        data: response.users,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: USERS_DATA_ARRAY_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: USERS_DATA_ARRAY_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getEmployeeDataApiCall = (address) => async (dispatch) => {
  await getExtendedDataApiCall(1, address)(dispatch);
};

export const getEmployerDataApiCall = (address) => async (dispatch) => {
  await getExtendedDataApiCall(0, address)(dispatch);
};

export const getExtendedDataApiCall = (role, address) => async (dispatch) => {
  if (role == 1) {
    dispatch({type: EMPLOYEE_DATA_REDUX, loader: true, message: null});
  } else {
    dispatch({type: EMPLOYER_DATA_REDUX, loader: true, message: null});
  }

  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_EXTENDED_USER_API}?address=${address}`,
      "GET"
    );
    if (response.message === "ok") {
      if (response.employee) {
        dispatch({
          type: EMPLOYEE_DATA_REDUX,
          data: response.employee,
          loader: false,
          message: null,
        });
      } else {
        dispatch({
          type: EMPLOYER_DATA_REDUX,
          data: response.employer,
          loader: false,
          message: null,
        });
      }
    } else {
      if (role == 1) {
        dispatch({
          type: EMPLOYEE_DATA_REDUX,
          data: {err: response.message},
          loader: false,
          message: response.message,
        });
      } else {
        dispatch({
          type: EMPLOYER_DATA_REDUX,
          data: {err: response.message},
          loader: false,
          message: response.message,
        });
      }
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: EXTENDED_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getCityArrayApiCall = (language) => async (dispatch) => {
  dispatch({ type: CITY_LIST_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${GET_CITY_ARRAY}?language=${language}`,
      "GET"
    );
    if (response.message === "ok") {
      dispatch({
        type: CITY_LIST_DATA_REDUX,
        data: response.data,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: CITY_LIST_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: CITY_LIST_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const uploadProfileImageApiCall = (formdata) => async (dispatch) => {
  dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(
      formdata,
      UPLOAD_PROFILE_IMAGE_API,
      "POST",
      true
    );
    if (response.message === "ok") {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
      //navigation.goBack();
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const startOfAnChatApi = (body) => async (dispatch) => {
  dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(body, START_OF_AN_CHAT_API, "POST");
    if (response.message === "ok") {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const getCountChatApiCall = (address) => async (dispatch) => {
  dispatch({ type: COUNT_CHAT_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(
      null,
      `${COUNT_OF_CHAT_API}?address=${address}`,
      "GET"
    );
    if (response.message === "ok") {
      dispatch({
        type: COUNT_CHAT_REDUX,
        data: response.count,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: COUNT_CHAT_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: COUNT_CHAT_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const updateUserCityApi = (body) => async (dispatch) => {
  dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(body, UPDATE_USER_CITY_API, "POST");
    if (response.message === "ok") {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const updateUserLanguageApi = (body) => async (dispatch) => {
  dispatch({ type: SAVE_DATA_REDUX, loader: true, message: null });
  try {
    const response = await SERVER_REQUEST(body, UPDATE_USER_LANGUAGE_API, "POST");
    if (response.message === "ok") {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: null,
      });
    } else {
      dispatch({
        type: SAVE_DATA_REDUX,
        loader: false,
        message: response.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SAVE_DATA_REDUX,
      loader: false,
      message: error.Message,
    });
  }
};

export const sendVerifyEmailApiCall = body => async dispatch => {
  dispatch({
    type: EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX,
    loader: true,
    message: null,
  });
  try {
    const response = await SERVER_REQUEST(body, VERIFY_EMAIL_SEND, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX,
        loader: false,
        message: null,
      });
      document.querySelector('.verifyEmailCode').classList.remove('hideModal')
    } else {
      dispatch({
        type: EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX,
        loader: false,
        message: response.error,
      });
      alert(response.message);
    }
  } catch (error) {
    dispatch({
      type: EMPLOYER_VERITYWEBSITE_EMAIL_SEND_REDUX,
      loader: false,
      message: error.Message,
    });
    alert(error.Message);
  }
};

export const verifyEmailCodeApiCall = (body, navigation) => async dispatch => {
  dispatch({
    type: EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX,
    loader: true,
    message: null,
  });
  try {
    const response = await SERVER_REQUEST(body, VERIFY_EMAIL_CONFIRM, 'POST');
    if (response.message === 'ok') {
      dispatch({
        type: EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX,
        loader: false,
        message: null,
      });
      document.querySelector('.submittedSuccessModal').classList.add('showModal')
    } else {
      dispatch({
        type: EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX,
        loader: false,
        message: response.error,
      });
      document.querySelector('.submittedFailedModal').classList.add('showModal')
    }
  } catch (error) {
    dispatch({
      type: EMPLOYER_VERITYWEBSITE_EMAILCODE_REDUX,
      loader: false,
      message: error.Message,
    });
    alert(error.Message);
  }
};
