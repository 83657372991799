const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="text-center">
          <div className="ftr-social-block">
            <span className="fontfamily-regular2 fontsize-14 white">
              <img src={require("../../assets/images/structure/ftr-icon1.svg").default} alt="" /> GitHub
            </span>
            <span className="fontfamily-regular2 fontsize-14 white">
              <img src={require("../../assets/images/structure/ftr-icon1.svg").default} alt="" /> Twitter
            </span>
            <span className="fontfamily-regular2 fontsize-14 white">
              <img src={require("../../assets/images/structure/ftr-icon1.svg").default} alt="" /> Telegram
            </span>
            <span className="fontfamily-regular2 fontsize-14 white">
              <img src={require("../../assets/images/structure/ftr-icon1.svg").default} alt="" /> Medium
            </span>
          </div>
          <div className="mt-15 d-flex align-items-center justify-content-center">
            <p className=" fontsize-14 white mb-0 mr-50">© 0xHIRE 2022</p>{" "}
            <div className=" fontsize-14 white">
              Term Of Use
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
