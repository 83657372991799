import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../../components/Footer";
import CitySelectionModal from "../../components/Modals/CitySelectionModal";
import { createUserApiCall, getCityArrayApiCall } from "../../redux/actions";
import { GET_ADDRESS, GET_CURRENT_LANGUAGE, GET_CURRENT_ROLE } from "../../shared/Storage";

const ChooseLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentCity = useSelector((item: any) => item?.userReducer?.city);

  const onPressNext = async () => {
    const address = await GET_ADDRESS();
    const userType = await GET_CURRENT_ROLE();
    const language = await GET_CURRENT_LANGUAGE();
    const body = {
      address: address,
      user_type: userType,
      language: language,
      city: currentCity,
    };
    createUserApiCall(body, navigate, userType)(dispatch);
  };

  const fetchCities = async () => {
    const language = await GET_CURRENT_LANGUAGE();
    getCityArrayApiCall(language)(dispatch);
  };

  useEffect(() => {
    fetchCities();
  });

  return (
    <>
      <div className="main-wrapper front-layout">
        <CitySelectionModal />
        <section className="choose-option-sec1">
          <div className="back-btn">
            <img
              src={
                require("../../assets/images/structure/back-icon.svg").default
              }
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="text-center choose-box">
                  <h5
                    className="blue70 fontfamily-blackitalic mb-30 fontsize-22 magic-heading"
                    //style="text-align: left;"
                  >
                    Your prefered working location
                  </h5>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target=".citiesDialog"
                    className="mb-10"
                  >
                    <div className="form-group form-input searchInput">
                      <input
                        //type="text"
                        placeholder="Enter a city name"
                        className="form-control"
                        readOnly
                        value={currentCity}
                      />
                      <img
                        src={
                          require("../../assets/images/structure/search.svg")
                            .default
                        }
                        alt=""
                        className="searchIcon"
                      />
                    </div>
                  </div>
                  <div className="mt-40">
                    <button disabled={currentCity.length === 0} onClick={onPressNext} className="btn next-btn">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default ChooseLocation;
