import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { GET_ADDRESS, GET_USER } from "../../shared/Storage";
import ChooseLanguage from "../chooseLanguage";
import ChooseLocation from "../chooseLocation";
import ChooseRole from "../chooseRole";

const Register = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <ChooseRole handleSteps={setPage} />;
      case 1:
        return <ChooseLanguage handleSteps={setPage} />;
      case 2:
        return <ChooseLocation />;
      default:
        return <ChooseRole />;
    }
  };

  useEffect(() => {
    (async () => {
      const user = await GET_USER();
      const address = await GET_ADDRESS();
      if (user) {
        navigate("/profile");
      }
      if(!address) {
        navigate("/")
      }
    })();
  }, []);

  return <>
    {conditionalComponent()}
  </>;
};

export default Register;
