import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Client } from "@xmtp/xmtp-js";

import { getUserDataApiCall } from "../../redux/actions";
import { useAppStore } from "../../store/app";

import NotificationModal from "../../components/Modals/NotificationModal"

const EnableXmtp = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signer = useAppStore((state) => state.signer);
  const client = useAppStore((state) => state.client);
  const setClient = useAppStore((state) => state.setClient);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [showErrorNotify, setNotify] = useState(false);

  const errorMsg = "enable xmtp failed";

  const enable = async () => {
    if (!signer) {
      return;
    }

    setIsRequestPending(true);

    try {
      const response = await Client.create(signer);
      setClient(response);

      const address = await signer.getAddress();
      // Navigates to /create or /profile from here.
      await getUserDataApiCall(address, navigate)(dispatch);

    } catch (e) {
      console.error(e);
      setNotify(true);
      setClient(null);
    }

    setIsRequestPending(false);

  }

  return (
    <>
      <div className="main-wrapper front-layout xmtp-mainwrapper">
        { showErrorNotify && <NotificationModal errorMsg={errorMsg} closeNotify={() => {setNotify(false)}}/>}
        <section className="choose-option-sec1">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="text-center choose-box">
                  <h4>
                    {t("The app is built with XMTP. Please sign a message to enable it.")}
                  </h4>
                  <div className="mt-40">
                    <button
                      className="btn next-btn"
                      onClick={() => enable()}
                      disabled={isRequestPending}
                    >
                      {t("Enable XMTP")} {isRequestPending && <span className="icon-spinner" ></span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EnableXmtp;
