import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeDataApiCall,
  getEmployerDataApiCall,
  getExtendedDataApiCall,
  getPositionsArrayApiCall,
  getSkillsArrayApiCall,
  updateUserLanguageApi,
} from "../../redux/actions";
import {
  GET_ADDRESS,
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_ROLE,
  SET_CURRENT_LANGUAGE,
  SET_POSITIONS_LIST,
  SET_SKILLS_LIST,
} from "../../shared/Storage";

const LanguageSelectionModal = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>("en");
  const [selectedLang, setSelectedLang] = useState("English");
  const [languages, setLanguages] = useState([
    { label: "Ukrainian", value: "uk" },
    { label: "English", value: "en" },
  ]);
  const { skillsData, positionsData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );

  useEffect(() => {
    if (skillsData) {
      SET_SKILLS_LIST(skillsData);
    }
    if (positionsData) {
      SET_POSITIONS_LIST(positionsData);
    }
  }, [skillsData, positionsData]);

  useEffect(() => {
    if (value) {
      (async () => {
        await i18n.changeLanguage(value);
        await SET_CURRENT_LANGUAGE(value);
        await getPositionsArrayApiCall(value)(dispatch);
        await getSkillsArrayApiCall(value)(dispatch);
      })();
    }
  }, []);

  const changeLanguage = async () => {
    await i18n.changeLanguage(value);
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      language: value
    };
    await updateUserLanguageApi(body)(dispatch);
    await getExtendedDataApiCall(address)(dispatch);
  }

  return (
    <div
      className="modal fade languageDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="languageDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="jobtypeBox">
              <h5 className="text-xs-center modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                {t('Your prefered language')}
              </h5>
              <div className="mb-10">
                <div className="form-group">
                  <div className="selectDropdown">
                    <button
                      id="SelectClaim"
                      data-bs-toggle="dropdown"
                      className="form-select"
                      aria-expanded="false"
                    >
                      {selectedLang}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-start">
                      {languages.map((lang) => (
                        <li
                          key={lang.value}
                          className="radiobox-image"
                          onClick={() => {
                            setValue(lang.value);
                            setSelectedLang(lang.label);
                          }}
                        >
                          <input
                            type="radio"
                            id="english"
                            name="lang"
                            value={value}
                          />
                          <label>{lang.label}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-30 text-center" data-bs-dismiss="modal">
                <button
                  className="btn width200"
                  onClick={changeLanguage}
                >CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectionModal;
