import { useEffect } from "react";

import Footer from "../../components/Footer";

const Home = () => {

  return (
    <>
      <div className="main-wrapper front-layout">
        <section className="home-sec1">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="text-center px-2">
                  <h1 className="blue fontfamily-bold2 mb-2 font-sm-40">
                    Find Jobs & Talents In Web3
                  </h1>
                  <p className="fontsize-24 fontfamily-regular2 blue5c">
                    Find great Web3 jobs at startups that use blockchain
                    technology.
                  </p>
                  <div className="d-flex align-items-center home-recive-email">
                    <p className="fontsize-16 fontfamily-regular blue mb-0">
                      Receive new web3 jobs directly into your email
                    </p>
                    {/* <button className="btn fill-black btn-small btn-rad8 width200" style="text-align: left;margin-right:8px;" ><img src="assets/images/structure/email.svg" alt="" /> </button> */}
                    <div>
                      <div className="form-group email-box">
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                    <button className="btn btn-small">
                      Subscribe{" "}
                      <img
                        src={
                          require("../../assets/images/structure/arrow-right.svg")
                            .default
                        }
                        alt=""
                        className=""
                      />
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-center joingroup-box">
                    <img
                      src={require("../../assets/images/products/p-group.png").default}
                      alt=""
                      className="mr-10"
                    />{" "}
                    <p className="fontsize-14 blue mb-0 fontfamily-regular mb-0">
                      Join 2,752+ people getting web3 jobs
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="hm-rgt-col-sec1">
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon1.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Substrate Runtime Engineer
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon2.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Growth Marketing Manager - France
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          France (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon3.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Blockchain Core Developer - Golang, Ethereum
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          London, UK (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon4.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Front End Web3 Engineer
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          London, UK (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon5.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Senior Blockchain Engineer
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          London, UK (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon6.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Senior Product Designer
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          London, UK (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center row1">
                    <img
                      src={require("../../assets/images/structure/h-icon7.png").default}
                      alt=""
                      className=""
                    />
                    <div className="pl-10">
                      <p className="fontfamily-regular black mb-0">
                        Front-End Developer - Remote
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="fontfamily-regular black mb-1 mr-10">
                          <img
                            src={
                              require("../../assets/images/structure/full-time.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          Full-Time
                        </p>
                        <p className="fontfamily-regular black mb-2">
                          <img
                            src={
                              require("../../assets/images/structure/worldwide.svg")
                                .default
                            }
                            alt=""
                            className="mr-1"
                          />{" "}
                          London, UK (Remote)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <a href="#" className="fontsize-20 fontfamily-regular blue">More</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default Home;
