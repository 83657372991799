const AccountVerificationModal = ({handleOauthPress, isTwitterClick}: any) => {
  return (
    <div
      className="modal fade verifyModal modalwidth428"
      tabIndex={-1}
      aria-labelledby="verifyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxVerify">
          <h5 className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center">
            Verification
          </h5>
          <h5
            className="mt-25 text-center fontfamily-bold"
            style={{ color: "rgba(112, 78, 248, 0.995)" }}
          >
            <i>
              Please verify your account to use more feature，you have 3 Options
              .
            </i>
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeVerifyBtn"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="profile-filled-input-box">
              <div className="form-group mt-20">
                <img
                  src={require("../../assets/images/structure/logo3.png").default}
                  alt=""
                  className="logoVerify"
                />
                <input
                  type="text"
                  className="form-control formInput "
                  placeholder="Verify Your Offcial Twitter"
                  aria-label="Verify Your Offcial Twitter"
                />
                <div className="verifyInput"
                  onClick={() => handleOauthPress('twitter')}
                >
                {  isTwitterClick ? (<>Verifying <span className="icon-spinner"></span></>) :
                  <>Verify</>
                }
                </div>
              </div>
              <div className="form-group">
                <img
                  src={require("../../assets/images/Bar/Frame 33899.svg").default}
                  alt=""
                  className="logoVerify"
                />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Verify Your Offcial E-mail"
                  aria-label="Verify Your Offcial E-mail"
                />
                <div
                  className="verifyInput"
                  data-bs-toggle="modal"
                  data-bs-target=".emailModal"
                >
                  Verify
                </div>
              </div>
              <div className="form-group">
                <img
                  src={require("../../assets/images/Bar/twitter.svg").default}
                  alt=""
                  className="logoVerify"
                />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Fill in Our Serviece Form"
                  aria-label="Fill in Our Serviece Form"
                />
                <div className="verifyInput">Verify</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationModal;
