import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Conversation, DecodedMessage, Stream } from "@xmtp/xmtp-js";
import InfiniteScroll from "react-infinite-scroll-component";

import { useAppStore } from "../../../store/app";
import { GET_CURRENT_ROLE, GET_ADDRESS } from "../../../shared/Storage";
import { startOfAnChatApi } from "../../../redux/actions";
import { checkIfPathIsEns, shortAddress, truncate } from "../../../helpers";

const isOnSameDay = (d1?: Date, d2?: Date): boolean => {
  return d1?.toDateString() === d2?.toDateString();
};

const formatDate = (d?: Date) =>
  d?.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

const PAGE_SIZE = 10;

const MessageComposer = ({ onClose, peerAddress,  }: any) => {
  const {employeeData, employerData} = useSelector((item: any) => item?.userReducer)
  const [userRole, setUserRole] = useState<string | null>();
  const [recipientInfo, setRecipientInfo] = useState<any>();

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [messages, setMessages] = useState<any>();

  const pageCached = useRef<any>(null);
  const pageStream = useRef<any>(null);

  let lastMessageDate: Date | undefined;

  const [messageInput, setMessageInput] = useState("");

  const dispatch = useDispatch();
  const client = useAppStore((state) => state.client);
  const [conversation, setConversation] = useState<Conversation | null>(null);

  // Creates new conversation.
  useEffect(() => {
    const work = async () => {
      if (!client || !peerAddress || checkIfPathIsEns(peerAddress)) {
        return;
      }

      setConversation(await client.conversations.newConversation(peerAddress));

      // post of start_of_an_chat
      const address = await GET_ADDRESS();
      const body = {
        from_address: address,
        to_address: peerAddress
      }
      await startOfAnChatApi(body)(dispatch);
    };

    work().catch(console.error);
  }, [client, peerAddress]);

  // Fetch data for the 1st time.
  useEffect(() => {
    if (!conversation) return;

    pageStream.current = conversation.messagesPaginated({ pageSize: PAGE_SIZE });

    fetchMoreData().catch(console.error);
  }, [conversation]);

  // Handles new messages.
  useEffect(() => {
    const work = async () => {
      if (!client || !conversation) {
        return;
      }

      for await (const oneMessage of await conversation.streamMessages()) {
        //if (message.senderAddress === client.address) {
          // This message was sent from me
        //  continue
        //}

        setMessages([oneMessage, ...(messages || [])]);
        console.log(`New message from ${oneMessage.senderAddress}: ${oneMessage.content}`)
      }
    }

    work().catch(console.error);
  }, [client, conversation, messages]);

  const onSubmit = async (event: any) => {
    if (!messageInput) {
      return;
    }

    if (!conversation) {
      return;
    }

    if(event.key !== 'Enter' && event.key !== undefined) {
      return;
    }

    await conversation.send(messageInput);

    setMessageInput("");
  };

  const checkProfileImageSrc = () => {
    return recipientInfo?.image
      ? recipientInfo?.image
      : require("../../../assets/images/products/portrait_default.png").default;
  };

  const fetchMoreData = async () => {
    if (!conversation) {
      return;
    }

    let isFirstTime = true;

    if (pageCached.current) {
      setMessages([...(messages || []), ...pageCached.current]);
      pageCached.current = null;
      isFirstTime = false;
    }

    for await (let page of pageStream.current) {
      if (isFirstTime) {
        setMessages([...([]), ...page]);
        isFirstTime = false;
      } else {
        pageCached.current = page;
        break;
      }
    }

    setHasMore(!!pageCached.current);
  };

  // Loads employee or employer data.
  useEffect(() => {
    (async () => {
      const role = await GET_CURRENT_ROLE();
      setUserRole(role)
      setRecipientInfo(role === '0' ? employeeData : employerData)
    })()
  }, [employeeData, employerData])

  return (
    <div className="col-xl-8 col-lg-8 col-md-8 col-md-12 col-12 chat-content-main-box">
      <div className="chatboard-box" style={{height:"100%"}}>
        <div className="chatHeader d-flex">
          <img
            src={checkProfileImageSrc()}
            className="ctr-img"
            alt=""
          />
          <div className="pl-20">
            {recipientInfo && <p className="fontsize-12 fontfamily-bold mb-0">{recipientInfo?.name}</p>}
            {userRole === '1' && (
              <p className="fontfamily-medium mb-0 fontsize-12">
              {recipientInfo?.project}{" "}
              <img
                src={
                  require("../../../assets/images/structure/star.svg").default
                }
                alt=""
                className="starIcon"
              />
            </p>
            )}
          </div>

          <button
            type="button"
            className="chat-btn-close"
	    onClick={() => onClose()}
	  >
	    <img
	      src={
		require("../../../assets/images/structure/close-circle-outline.svg")
		  .default
	      }
	      alt=""
	    />
	  </button>
        </div>

        <div id="scrollableDiv"
            className="chatboard w-100"
            style={{
              height: 'calc(100% - 120px)',
              paddingRight: '20px',
              overflow: 'auto',
              display: 'flex',
              position: 'absolute',
              flexDirection: 'column-reverse'
            }}>
          {messages && (
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreData}
              hasMore={hasMore}
              inverse={true}
              loader={<h4>Loading...</h4>}
              endMessage={
                <div/>
              }
              scrollableTarget="scrollableDiv"
              style={{
                display: 'flex',
                flexDirection: 'column-reverse'
              }}
            >
            {messages.map((msg: DecodedMessage, index: number) => {
              const dateHasChanged = !isOnSameDay(lastMessageDate, msg.sent);
              lastMessageDate = msg.sent;
              return (
                <div key={index}>
                  {dateHasChanged ? (
                    <p
                      key={msg.id + "divider"}
                      className="fontsize-12 fontfamily-regular gray80 mb-15 text-center"
                    >
                      {formatDate(msg.sent)}
                    </p>
                  ) : null}
    
                  {peerAddress !== msg.senderAddress ?
                    <div className="msgboxReply">
                    <div className="msgmainrw">
                      <div className="d-flex align-items-center">
                        <div className="mgsReply">{msg.content || ''}</div>
                        <img
                          src={require("../../../assets/images/products/portrait_default.png").default}
                          className="chatmsg-img"
                          alt=""
                        />
                      </div>
                      <p className="fontfamily-regular mb-0 fontsize-10 chttime">
                        09:30 am{" "}
                        <img
                          src={
                            require("../../../assets/images/structure/double-check.svg")
                              .default
                          }
                          alt=""
                          className=""
                        />
                      </p>
                    </div>
                  </div>:
                  <div className="msgboxSent">
                  <div className="msgmainrw">
                    <div className="d-flex align-items-center">
                      <img
                        src={require("../../../assets/images/products/portrait_default.png").default}
                        className="chatmsg-img"
                        alt=""
                      />
                      <div className="mgsSent">{msg.content || ''}</div>
                    </div>
                    <p className="fontfamily-regular mb-0 fontsize-10 chttime">
                      09:30 am{" "}
                    </p>
                  </div>
                </div>
                  }
                  
                </div>
              );
            })}
            </InfiniteScroll>
          )}
        </div>
        <div />
        <div className="chatboxftr">
          <div className="relative">
            <img
              src={
                require("../../../assets/images/structure/attach.svg").default
              }
              className="attachBtn"
              alt=""
            />
            <input
              type="text"
              placeholder="Write your massage"
              className="chatinput"
              onChange={(evt) => setMessageInput(evt.target.value)}
              value={messageInput}
              onKeyUp={onSubmit}
            />
            <img
              src={require("../../../assets/images/structure/send.svg").default}
              className="sendBtn"
              alt=""
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
