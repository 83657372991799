const JobTypeModal = ({setJobType}: any) => {
  
  return (
    <div
      className="modal fade jobTypeDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="jobTypeDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="jobtypeBox">
              <h5 className="text-xs-center modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                Job Type
              </h5>
              <div className="selectchips d-flex flex-wrap align-items-center justify-content-between">
                <div className="category-chips-box">
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips" onClick={() => setJobType(null)}>
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Any
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setJobType(0)} >
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Full-Time
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setJobType(1)}>
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Part-Time
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setJobType(2)}>
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Contract
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setJobType(3)}>
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Internship
                    </p>
                  </div>
                </div>
                <div className="category-chips-box" onClick={() => setJobType(4)}>
                  <input type="radio" name="categorySelect" />
                  <div className="category-chips">
                    <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                      Volunteer
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-30 text-center" data-bs-dismiss="modal">
                <button className="btn width200" >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobTypeModal;
