import {
  SAVE_DATA_REDUX,
  SKILL_LIST_DATA_REDUX,
  POSITION_LIST_DATA_REDUX,
  COUNT_EMPLOYEE_COLLECTION_DATA_REDUX,
  EMPLOYEE_EDUCATION_LIST_DATA_REDUX,
  EMPLOYEE_WORK_LIST_DATA_REDUX,
} from '../types/types';

const initialState = {
  loading: false,
  errorMessage: null,
  skillsData: null,
  positionsData: null,
  countSavedPositionsData: null,
  educationListData: null,
  workListData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        errorMessage: action.message,
      };
    case SKILL_LIST_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        skillsData: action.data,
        errorMessage: action.message,
      };
    case POSITION_LIST_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        positionsData: action.data,
        errorMessage: action.message,
      };
    case COUNT_EMPLOYEE_COLLECTION_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        countSavedPositionsData: action.data,
        errorMessage: action.message,
      };
    case EMPLOYEE_EDUCATION_LIST_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        educationListData: action.data,
        errorMessage: action.message,
      };
    case EMPLOYEE_WORK_LIST_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        workListData: action.data,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
