import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CitySelectionModal from "../../components/Modals/CitySelectionModal";
import JobTypeModal from "../../components/Modals/JobTypeModal";
import KeywordsModal from "../../components/Modals/KeywordsModal";
import LoadingOverlay from "../../components/Modals/LoadingOverlay";
import PositionDetailsModal from "../../components/Modals/PositionDetailsModal";
import SalaryRangeModal from "../../components/Modals/SalaryRangeModal";
import SearchResultItem from "../../components/SearchResultItem/SearchResultItem";
import {
  getDiscoverPositionsListApiCall,
  savePositionCollectionApiCall,
  getCityArrayApiCall,
} from "../../redux/actions";
import { BACKGROUND_COLOR } from "../../shared/Colors";
import {
  GET_ADDRESS,
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_ROLE,
  GET_POSITIONS_LIST,
} from "../../shared/Storage";

const workTypes = [
  "Full-time",
  "Part-time",
  "Contract",
  "Internship",
  "Volunteer",
  "Any Job Type",
];

const cityTypes = ["Any", "Remote", "On-site"];

const salaryTypes = ["Hourly", "Monthly", "Any Salary"];

const DiscoverEmployee = () => {
  const dispatch = useDispatch();

  const [details, setDetails] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [positionsList, setPositionsList] = useState<string[]>();
  const [searchResult, setSearchResult] = useState<string[]>();
  const [isHided, setIsHided] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [recommended, setRecommended] = useState(0);
  const [jobType, setJobType] = useState<number | null>(null);
  const [salaryType, setSalaryType] = useState(null);
  const [salaryLow, setSalaryLow] = useState("");
  const [confirmSalary, setConfirmSalary] = useState(false);
  const [salaryHigh, setSalaryHigh] = useState("");
  const [keywords, setKeyWords] = useState<string[]>([]);
  const [titleTemp, setTitleTemp] = useState("");
  const [title, setTitle] = useState("");

  const [fetching, setFetching] = useState(false);

  const { city, experienceYears } = useSelector(
    (item: any) => item?.userReducer
  );
  const { discoverPositionsData } = useSelector(
    (item: any) => item?.discoverReducer
  );

  const onConfirmHandler = (item: string) => {
    setTitleTemp(item);
    setTitle(item);
    searchForPosition("");
  };

  const handleTitleTemp = (val: any) => {
    setTitleTemp(val);
    searchForPosition(val);
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setTitle(titleTemp);
    }
  };

  const checkIsPositionSaved = (item: any) => {
    return item?.collected
      ? require("../../assets/images/structure/save-pink.svg").default
      : require("../../assets/images/structure/save.svg").default;
  };

  const formateDate = (date: string) => {
    const postedDate = new Date(date);
    const formatedDate = new Date(postedDate).toLocaleDateString("en-ZA");
    return formatedDate;
  };

  const searchForPosition = (searchPhrase: string) => {
    if (searchPhrase.length > 0) {
      const searchText = searchPhrase.toLowerCase();
      let filtredPositions: string[] = [];
      positionsList?.map((item: string) => {
        const currentItem = item.toLowerCase();
        if (currentItem === searchText || currentItem.startsWith(searchText)) {
          filtredPositions.push(item);
        }
      });
      setSearchResult(filtredPositions);
    } else {
      setSearchResult(positionsList);
    }
  };

  const onPressFavorite = async (item: any) => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      position_id: item?.id,
    };
    await savePositionCollectionApiCall(body)(dispatch);
    await getDiscoverPositionsListApiCall(address, {
      recommended,
      title,
      city,
      jobType,
      salaryType,
      salaryLow,
      salaryHigh,
    })(dispatch);
  };

  const fetchOther = async () => {
    const language = await GET_CURRENT_LANGUAGE();
    const positionsList = await GET_POSITIONS_LIST();
    setPositionsList(positionsList?.split(","));
    await getCityArrayApiCall(language)(dispatch);
  };

  const fetchData = async () => {
    setFetching(true); 
    const address = await GET_ADDRESS();
    await getDiscoverPositionsListApiCall(address, {
      recommended,
      title,
      city,
      jobType,
      salaryType,
      salaryLow,
      salaryHigh,
      keywords
    })(dispatch);
    setConfirmSalary(false);
    setFetching(false);
  };

  useEffect(() => {
    fetchOther();
  }, []);

  useEffect(() => {
    fetchData();
  }, [recommended, city, jobType, salaryType, keywords, title, page]);

  useEffect(() => {
    if (!confirmSalary && salaryLow && salaryHigh) return;
    fetchData();
  }, [confirmSalary]);

  return (
    <>
      <div className="main-wrapper discover-empolyee-wrapper">
        {fetching && <LoadingOverlay />}
        <JobTypeModal setJobType={setJobType} />
        <CitySelectionModal />
        <SalaryRangeModal
          setSalaryType={setSalaryType}
          salaryLow={salaryLow}
          salaryHigh={salaryHigh}
          setSalaryLow={setSalaryLow}
          setSalaryHigh={setSalaryHigh}
          setConfirmSalary={setConfirmSalary}
        />
        <KeywordsModal setKeyWords={setKeyWords} />
        <PositionDetailsModal details={details} />
        <section className="employee-discover-sec1">
          <div className="container filterContainer">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-2">
                <div className="d-flex flex-wrap discover-list">
                  <div
                    className="filtercheckbox"
                    data-bs-toggle="modal"
                    data-bs-target=".jobTypeDialog"
                  >
                    <p className="fontsize-12 fontfamily-regular mb-0">
                      {jobType !== null ? workTypes[jobType] : workTypes[5]}
                    </p>
                  </div>
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".citiesDialog"
                    >
                      {city.length > 0 ? city : "Location"}
                    </p>
                  </div>
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".salaryRangeDialog"
                    >
                      {salaryLow && salaryHigh ? `${salaryLow}-${salaryHigh}` : salaryTypes[2]}
                    </p>
                  </div>
                  <div className="filtercheckbox">
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      data-bs-toggle="modal"
                      data-bs-target=".keywordsDialog"
                      style={{ color: keywords?.length > 0 ? "#704EF8" : "" }}
                    >
                      {keywords.length > 0 ? keywords.join(', ') : 'KeyWords'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-3">
                <div className="d-flex flex-wrap discover-list-right">
                  <div
                    className="filtercheckbox"
                    onClick={() => setRecommended(0)}
                  >
                    <input type="radio" name="recnew" />
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      style={{ color: recommended === 0 ? "#704EF8" : "" }}
                    >
                      Recommend
                    </p>
                  </div>
                  <div
                    className="filtercheckbox"
                    onClick={() => setRecommended(1)}
                  >
                    <input type="radio" name="recnew" />
                    <p
                      className="fontsize-12 fontfamily-regular mb-0"
                      style={{ color: recommended === 1 ? "#704EF8" : "" }}
                    >
                      Newest
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xs-1">
                <div className="form-group form-input searchInput">
                  <input
                    type="text"
                    placeholder="Enter a position name, e.g. Software Engineer"
                    className="form-control"
                    onChange={(evt) => handleTitleTemp(evt.target.value)}
                    value={titleTemp}
                    onKeyDown={handleKeyDown}
                  />
                  <img
                    src={
                      require("../../assets/images/structure/search.svg")
                        .default
                    }
                    alt=""
                    className="searchIcon"
                  />
                  {titleTemp.length !== 0 && (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      backgroundColor: BACKGROUND_COLOR,
                      opacity: 0.9,
                      paddingLeft: 48,
                    }}
                  >
                    {searchResult?.map((item, index) => (
                      <SearchResultItem
                        key={index}
                        onPress={() => onConfirmHandler(item)}
                        text={titleTemp}
                        result={item}
                      />
                    ))}
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {discoverPositionsData?.map((item: any, index: number) => (
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" key={index}>
                  <div className="productListBlock"
                    onClick={() => {
                      setDetails(item);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target=".employeetalktoHR"
                  >
                    <div className="product-row1 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/products/product1.png").default}
                        className="prodImage"
                        alt=""
                      />
                      <div
                        className="pl-15"
                      >
                        <p className="fontsize-16 black fontfamily-medium mb-0">
                          {item?.title}
                        </p>
                        <p className="fontsize-14 gray82 fontfamily-medium mb-0">
                          {item.project}
                          <img
                            src={
                              require("../../assets/images/structure/star.svg")
                                .default
                            }
                            alt=""
                            className="starIcon"
                          />
                        </p>
                        <p className="fontsize-12 gray82 fontfamily-medium mb-0">
                          {`Posted ${formateDate(item?.updatedAt)}`}
                        </p>
                      </div>
                      <div
                        className="likebox ml-auto"
                        onClick={() => onPressFavorite(item)}
                      >
                        <img src={checkIsPositionSaved(item)} alt="" />
                      </div>
                    </div>
                    <div className="product-row2 d-flex flex-wrap align-items-center">
                      <span className="chips chips-fill-orange">
                        {workTypes[item?.workType]}
                      </span>
                      <span className="chips chips-fill-green">
                        {cityTypes[item?.cityType]}
                      </span>
                      <span className="chips chips-outline-green">
                        {item?.category}
                      </span>
                      {item?.skills?.split(",").map((item: string, index: number) => (
                        <span className="chips" key={index}>{item}</span>
                      ))}
                    </div>
                    <div className="product-row3 d-flex align-items-center">
                      <img
                        src={
                          require("../../assets/images/structure/share.svg")
                            .default
                        }
                        alt=""
                        className="shareIcon"
                      />
                      <div className="ml-auto">
                        <p className="fontsize-16 fontfamily-semibold blue70 mb-1">
                          {`$${item?.salaryLow}~$${item?.salaryHigh} / ${
                            salaryTypes[item?.salaryType]
                          }`}
                        </p>
                        <div className="viewmore-a fontsize-12 fontfamily-regular">
                          View More{" "}
                          <img
                            src={
                              require("../../assets/images/structure/arrow-gray.svg")
                                .default
                            }
                            alt=""
                            className="viewmoreArrow ml-10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default DiscoverEmployee;
