import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ChooseSkillsModal from "../../components/Modals/ChooseSkillsModal";
import DesiredPositionModal from "../../components/Modals/DesiredPositionModal";
import EducationExperienceModal from "../../components/Modals/EducationExperienceModal";
import EmployeeBasicInfoModal from "../../components/Modals/EmployeeBasicInfoModal";
import WorkingExperienceModal from "../../components/Modals/WorkingExperienceModal";
import {
  getCountSavedPositionsApiCall,
  getEmployeeDataApiCall,
  getUserDataApiCall,
  getPositionsArrayApiCall,
  getSkillsArrayApiCall,
  getCityArrayApiCall,
  getCountChatApiCall,
} from "../../redux/actions";
import { useTranslation } from "react-i18next";
import {
  GET_ADDRESS,
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_ROLE,
  GET_OAUTH_TOKEN,
  REMOVE_OAUTH_TOKEN
} from "../../shared/Storage";
import AddWorkExperienceModal from "../../components/Modals/AddWorkExperienceModal";
import AddEducationExperienceModal from "../../components/Modals/AddEducationExperienceModal";
import CitySelectionModal from "../../components/Modals/CitySelectionModal";
import LanguageSelectionModal from "../../components/Modals/LanguageSelectionModal";
import UploadImagePreviewModal from "../../components/Modals/UploadImagePreviewModal";
import SavedPositionsModal from "../../components/Modals/SavedPositionsModal";
import AppliedPositionModal from "../../components/Modals/AppliedPositionModal";

import LinkinLogo from '../../assets/images/structure/logo1.png'
import GithubLogo from '../../assets/images/structure/github.svg'
import TwitterLogo from '../../assets/images/structure/twitter.svg'
import ResumeLogo from '../../assets/images/structure/logo4.png'

const EmployeeProfile = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const { employeeData, userData } = useSelector(
    (item: any) => item.userReducer
  );

  const { countSavedPositionsData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );

  const { city, countChatData } = useSelector((item: any) => item?.userReducer);

  const [editItem, setEditItem] = useState();
  const [imgSrc, setImgSrc] = useState("");
  const [imgFile, setImgFile] = useState<File>();

  const [oauthAppName, setOauthAppName] = useState('');
  const [isBtnClick, setBtnClick] = useState(true);

  const inputFile = useRef<HTMLInputElement>(null);

  const [fields, setFields] = useState([
    {
      buttonTitle: 'Loading',
      action: 'connect linkedin',
      name: 'linkedin',
      logo: LinkinLogo,
      status: 'Connect'
    },
    {
      buttonTitle: 'Loading',
      action: 'connect github',
      name: 'github',
      logo: GithubLogo,
      status: 'Connect'
    },
    {
      buttonTitle: 'Loading',
      action: 'connect twitter',
      name: 'twitter',
      logo: TwitterLogo,
      status: 'Connect'
    },
    {
      buttonTitle: 'Upload',
      action: 'upload resume',
      logo: ResumeLogo
    },
  ]);

  const checkProfileImageSrc = () => {
    return employeeData?.image
      ? employeeData?.image
      : require("../../assets/images/structure/succes.png").default;
  };

  const onImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const fetchUserData = async () => {
    const address = await GET_ADDRESS();
    const role = await GET_CURRENT_ROLE();
    const language = await GET_CURRENT_LANGUAGE();
    if (role === "1") {
      await getEmployeeDataApiCall(address)(dispatch);
      await getUserDataApiCall(address)(dispatch);
      await getPositionsArrayApiCall(language)(dispatch);
      await getSkillsArrayApiCall(language)(dispatch);
      await getCityArrayApiCall(language)(dispatch);

      await Promise.all([
        getCountSavedPositionsApiCall(address)(dispatch),
        getCountChatApiCall(address)(dispatch)
      ])
    }
  };

  const handleOauthPress = async (appName: string) => {
    if (!isBtnClick) {
      return;
    }

    setBtnClick(false);
    setOauthAppName(appName);
    updateFieldsItemApp('Verifying', appName, 'verifying');

    const address = await GET_ADDRESS();
    const linkingURL = `${process.env.REACT_APP_API_KEY}${appName}/auth0?address=${address}&is_mobile=0`;

    REMOVE_OAUTH_TOKEN();
    window.open(linkingURL, '_blank');
  };

  const onPressGridBtn = (item: any) => {
    switch (item.action) {
      case 'connect linkedin':
        return handleOauthPress('linkedin');
      case 'connect github':
        return handleOauthPress('github');
      case 'connect twitter':
        return handleOauthPress('twitter');
      case 'upload resume':
        return console.log('upload resume');
      default:
        break;
    }
  };

  const updateFieldsItemApp = (val:any, name:any, status:any) => {
    fields.forEach(obj => {
      if (obj.name === name) {
        obj.buttonTitle = val;
        obj.status = status;
      }
    });
  };

  useEffect(() => {
    fetchUserData();

    const handleStorageChange = async() => {
      const oauth_token = await GET_OAUTH_TOKEN();
      if (oauth_token?.includes('isOauthVerified')) {
        const appName = oauth_token.split('_')[0];
        updateFieldsItemApp('Connected', appName, 'Connected');
        setFields(fields => [...fields]);
        setBtnClick(true);
      }
    }

    window.addEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    fields.forEach(item => {
      if (item.name) {
        updateFieldsItemApp('Connect', item.name, 'Connect');
      }
    });
    
    if (employeeData && employeeData.twitter) {
      updateFieldsItemApp('Connected', 'twitter', 'Connected');
    }

    if (employeeData && employeeData.linkedin) {
      updateFieldsItemApp('Connected', 'linkedin', 'Connected');
    }

    if (employeeData && employeeData.github) {
      updateFieldsItemApp('Connected', 'github', 'Connected');
    }

    if (employeeData && employeeData.language) {
      i18n.changeLanguage(employeeData.language);
    }

    setFields(fields => [...fields]);
  }, [employeeData]);

  return (
    <>
      <div className="main-wrapper bggrayfa good-profile-wrapper">
        <EmployeeBasicInfoModal />
        <EducationExperienceModal setEditItem={setEditItem} />
        <WorkingExperienceModal setEditItem={setEditItem} />
        <AddWorkExperienceModal data={editItem} setEditItem={setEditItem} />
        <AddEducationExperienceModal
          data={editItem}
          setEditItem={setEditItem}
        />
        <DesiredPositionModal />
        <LanguageSelectionModal />
        <ChooseSkillsModal />
        <CitySelectionModal />
        <SavedPositionsModal />
        <AppliedPositionModal />
        <UploadImagePreviewModal
          imgSrc={imgSrc}
          imgFile={imgFile}
          setImgSrc={setImgSrc}
        />
        <section className="profile-sec1 ">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-11 col-md-12 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-md-12 col-sm-12 col-12 profile-left-col">
                    <div className="profile-graybox1">
                      <img
                        src={
                          require("../../assets/images/structure/edit.svg")
                            .default
                        }
                        alt=""
                        className="prfileedit cursor-p"
                        data-bs-toggle="modal"
                        data-bs-target=".basicInfo"
                      />
                      <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-md-12 col-sm-12 col-12 mx-auto">
                          <div className="text-center">
                            <img
                              src={checkProfileImageSrc()}
                              className="profile-pImage"
                              style={{ borderRadius: 500 }}
                              alt=""
                              onClick={onImageClick}
                              //data-bs-toggle="modal"
                              //data-bs-target=".imagePreviewDialog"
                            />
                            <input
                              type="file"
                              accept="image/*"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onSelectFile}
                            />
                            <h5 className="fontsize-24 black mb-2 fontfamily-bold mt-10">
                              {employeeData?.name}
                            </h5>
                            <p className="gray80 fontfamily-medium mb-2 fontsize-14">
                              <img
                                src={
                                  require("../../assets/images/structure/location.svg")
                                    .default
                                }
                                alt=""
                                className="locationIcon mr-10"
                              />{" "}
                              {employeeData?.city}
                            </p>
                            <p
                              className="black fontfamily-regular fontsize-12 mb-15"
                              style={{ textAlign: "justify" }}
                            >
                              {employeeData?.bio
                                ? employeeData?.bio
                                : t("Write down your self-description")}
                            </p>
                            <div className="d-flex align-items-center justify-content-between prSocialIcons">
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/dribbble.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/linkein.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/github.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/twitter.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-graybox2 d-flex align-items-center mt-25">
                      <div className="d-flex prfile-tag">
                        <img
                          src={
                            countSavedPositionsData ? require("../../assets/images/structure/collection-pink.svg")
                              .default : require("../../assets/images/structure/collection.svg")
                              .default
                          }
                          alt=""
                          className="collectionIcon mr-20"
                        />
                        <div
                          className={ countSavedPositionsData ? "positiontabs d-flex align-items-center" : "positiontabs d-flex align-items-center disabled-positiontabs"}
                          data-bs-toggle="modal"
                          data-bs-target=".Savedposition"
                        >
                          <p className="fontsize-16 black mb-0 fontfamily-semibold d-flex align-items-center w-100">
                            Saved Positions{" "}
                            <span className={(countSavedPositionsData ? "fontfamily-bold posNo ml-auto orangefc" : "grayb5 posNo ml-auto orangefc")}>
                              {countSavedPositionsData}{" "}
                              <img
                                src={
                                  require("../../assets/images/structure/arrow-prev.svg")
                                    .default
                                }
                                alt=""
                                className="arrow-prev ml-3"
                              />
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex ml-auto prfile-tag">
                        <img
                          src={
                            countChatData ? require("../../assets/images/structure/applied.svg")
                              .default : require("../../assets/images/structure/applied-gray.svg")
                              .default
                          }
                          alt=""
                          className="applied mr-20"
                        />
                        <div
                          className={ countChatData ? "positiontabs d-flex align-items-center" : "positiontabs d-flex align-items-center disabled-positiontabs"}
                          data-bs-toggle="modal"
                          data-bs-target=".appliedposition"
                        >
                          <p className="fontsize-16 black mb-0 fontfamily-semibold d-flex align-items-center w-100">
                            Applied Position{" "}
                            <span className={(countChatData ? "fontfamily-bold posNo ml-auto blue70" : "grayb5 posNo ml-auto blue70")}>
                              {countChatData}{" "}
                              <img
                                src={
                                  require("../../assets/images/structure/arrow-prev.svg")
                                    .default
                                }
                                alt=""
                                className="arrow-prev ml-3"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-graybox3 d-flex align-items-center mt-25">
                      <div className="row w-100">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 col-sm-12 d-flex align-items-center">
                          <img
                            src={
                              require("../../assets/images/structure/smily-face.png")
                                .default
                            }
                            alt=""
                            className="faceIcon"
                          />
                          <div className="pl-15 flex-fill">
                            <p className="fontsize-14 text-uppercase black mb-2 fontfamily-semibold d-flex">
                              Credibility Score
                              <span
                                className="ml-auto"
                                style={{ fontStyle: "italic" }}
                              >
                                40%
                              </span>
                            </p>
                            <div className="progress credibilityscore">
                              {/* <div className="progress-bar" role="progressbar" style={{width: '40%'}} ariaValueNow={40} aria-valuemin="0" aria-valuemax="100"></div> */}
                            </div>

                            <p className="fontsize-14 text-uppercase black mb-2 fontfamily-semibold mt-2 d-flex">
                              Credibility Score{" "}
                              <span
                                className="ml-auto"
                                style={{ fontStyle: "italic" }}
                              >
                                20%
                              </span>
                            </p>
                            <div className="progress completionscore">
                              {/* <div className="progress-bar" role="progressbar" style={{width: "20%"}} aria-valuenow="20"
                            aria-valuemin="0" aria-valuemax="100"></div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 col-sm-12 text-center score-emoji-teext pl-30 pr-50">
                          <p className="fontsize-12 black mb-0 fontfamily-regular">
                            Your Credibility Score is below 60% ! You are not
                            able to chat with Hiring managers, or be recommended
                            to potential employers.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="profile-status-block mt-25">
                      <div className="tab-content mt-25" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pending"
                          role="tabpanel"
                          aria-labelledby="tab1"
                        >
                          <div className="d-flex flex-wrap prfile-socail-tab">
                            {fields.map((item: any, index) => (
                              <div className="profilestatuslogobox" key={index}>
                                <div>
                                  <img
                                    src={item.logo}
                                    alt=""
                                    className={"prLogo " + ((item.name == 'twitter' || item.name == 'github') ? "bigger-svg-logo" : " ")}
                                  />
                                {item.name ? (
                                  item.status === 'Connect' ? (
                                    <div className="connect-btn" onClick={() => onPressGridBtn(item)}>
                                      {item.buttonTitle}
                                      </div>
                                  ) : (
                                    item.status === 'verifying' ? (
                                      <div className="connect-btn" onClick={() => onPressGridBtn(item)}>
                                        {item.buttonTitle} <span className="icon-spinner"></span>
                                      </div>
                                    ) :
                                    <div className="succeed-a">
                                      {item.buttonTitle}
                                    </div>
                                  )
                                ) : (
                                  <div className="connect-btn">
                                    {item.buttonTitle}
                                  </div>
                                )}
                                </div>
                              </div>
                            ))}
                            <div className="profilestatuslogobox">
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/logo5.png")
                                      .default
                                  }
                                  alt=""
                                  className="prLogo"
                                />
                                <div
                                  className="connect-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target=".educationExperience"
                                >
                                  Add{" "}
                                </div>
                              </div>
                            </div>
                            <div className="profilestatuslogobox">
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/structure/logo6.png")
                                      .default
                                  }
                                  alt=""
                                  className="prLogo"
                                />
                                <div
                                  className="connect-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target=".workExperience"
                                >
                                  Add{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="finished"
                          role="tabpanel"
                          aria-labelledby="tab2"
                        >
                          ...
                        </div>
                        <div
                          className="tab-pane fade"
                          id="skipped"
                          role="tabpanel"
                          aria-labelledby="tab3"
                        >
                          ...
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-md-12 col-sm-12 col-12 profile-right-col profile-good-right-col">
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".desiredDialog"
                    >
                      <h6 className="fontsize-16 fontfamily-semibold black mb-20">
                        {t('Desired Position')}
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0">
                        {employeeData?.desiredPosition}
                      </p>
                    </div>
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".languageDialog"
                    >
                      <h6 className="fontsize-16 fontfamily-semibold black mb-20">
                        {t('Language')}
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0">
                        {employeeData?.language}
                      </p>
                    </div>
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".citiesDialog"
                    >
                      <h6
                        className="fontsize-16 fontfamily-semibold black mb-20"
                        data-bs-toggle="modal"
                        data-bs-target=".citiesDialog"
                      >
                        Location
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0">
                        {employeeData?.city}
                      </p>
                    </div>
                    <div
                      className="profile-rgt-white-box mb-15"
                      data-bs-toggle="modal"
                      data-bs-target=".skillsDialog"
                    >
                      <h6 className="fontsize-16 fontfamily-semibold black mb-20">
                        Skills
                      </h6>
                      <p className="fontsize-12 black0d fontfamily-regular black mb-0">
                        {employeeData?.skills}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmployeeProfile;
