import { useAppStore } from "../../../store/app";
import { Conversation, DecodedMessage } from "@xmtp/xmtp-js";
import { formatDate, truncate } from "../../../helpers";
import { useEffect } from "react";
import { getEmployeeDataApiCall, getEmployerDataApiCall, getUsersDataArrayApiCall } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GET_CURRENT_ROLE } from "../../../shared/Storage";

const extractAddressArray = (arr: any, prop: any) => {

  let extractedValue =  Array.from(arr.values()).map((item: any) => item[prop]);

  return extractedValue;

}
const checkProfileImageSrc = (item: any) => {
  return item?.image
    ? item?.image
    : require("../../../assets/images/products/portrait_default.png").default;
};

const fetchProfileDeatials = async (peerAddress: string, dispatch:any) => {
  const userRole = await GET_CURRENT_ROLE();
  if(userRole === '0') {
    await getEmployeeDataApiCall(peerAddress)(dispatch);
  } else {
    await getEmployerDataApiCall(peerAddress)(dispatch);
  }
  
}

const getLatestMessage = (messages: DecodedMessage[]): DecodedMessage | null =>
  messages?.length ? messages[messages.length - 1] : null;

const ConversationTile = ({
  conversation,
  setRecipientWalletAddr,
  setRecipientInfo,
  isSelected,
  userDataArray
}: any) => {
  const dispatch = useDispatch();
  const userData = userDataArray.find((obj: any) => {return obj.address === conversation.peerAddress})
  const convoMessages = useAppStore((state) => state.convoMessages);

  if (!convoMessages.get(conversation.peerAddress)?.length) {
    return null;
  }

  const latestMessage = getLatestMessage(
    convoMessages.get(conversation.peerAddress) ?? []
  );

  if (!latestMessage) {
    return null;
  }

  return (
    <div
      className="chatdtlrow d-flex"
      style={{ backgroundColor: isSelected ? "#EFEEF5" : "" }}
    >
      <div className="ctr-img-box">
        <img
          src={checkProfileImageSrc(userData)}
          className="ctr-img"
          alt=""
        />
      </div>
      <div
        className="ctrMsgDtl"
        onClick={() => {
          setRecipientWalletAddr(conversation.peerAddress)
          fetchProfileDeatials(conversation.peerAddress, dispatch);
        }}
      >
        <h6 className="mb-1">
          {userData?.name}{" "}
          <span className="chattmLft">{formatDate(latestMessage?.sent)}</span>
        </h6>
        <p className="p3">
          {latestMessage && truncate(latestMessage.content, 75)}
        </p>
        {/* <div className="msgcounter">2</div> */}
      </div>
    </div>
  );
};
const MessagesList = ({ recipientWalletAddr, setRecipientWalletAddr, setRecipientInfo }: any) => {
  const dispatch = useDispatch();
  const {userDataArray} = useSelector((item: any) => item?.userReducer);
  const conversations = useAppStore((state) => state.conversations);
  const convoMessages = useAppStore((state) => state.convoMessages);

  const orderByLatestMessage = (
    convoA: Conversation,
    convoB: Conversation
  ): number => {
    const convoAMessages = convoMessages.get(convoA.peerAddress) ?? [];
    const convoBMessages = convoMessages.get(convoB.peerAddress) ?? [];
    const convoALastMessageDate =
      getLatestMessage(convoAMessages)?.sent || new Date();
    const convoBLastMessageDate =
      getLatestMessage(convoBMessages)?.sent || new Date();
    return convoALastMessageDate < convoBLastMessageDate ? 1 : -1;
  };

  const fetchUsersArray = async () => {
    const arrayAddresses = extractAddressArray(conversations, 'peerAddress');
    await getUsersDataArrayApiCall(String(arrayAddresses))(dispatch);

  }

  useEffect(() => {
    if(conversations) {
      fetchUsersArray();
    }
  }, [])

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-md-12 col-12 chat-left-sidebar">
      <div className="chat-search-box">
        <input
          type="text"
          placeholder="Search a chat or message"
          className="chatSearchInp"
        />
        <img
          src={require("../../../assets/images/structure/search.svg").default}
          className="searchIcon"
          alt=""
        />
      </div>
      <div className="chat-left-content-box">
        {userDataArray &&
          Array.from(conversations.values())
            .sort(orderByLatestMessage)
            .slice(0, 20)
            .map((convo) => {
              const isSelected = recipientWalletAddr === convo.peerAddress;
              return (
                <ConversationTile
                  key={convo.peerAddress}
                  conversation={convo}
                  isSelected={isSelected}
                  setRecipientWalletAddr={setRecipientWalletAddr}
                  setRecipientInfo={setRecipientInfo}
                  userDataArray={userDataArray}
                />
              );
            })}
      </div>
    </div>
  );
};

export default MessagesList;
