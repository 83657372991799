import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../../components/Footer";
import { PagesProps } from "../register/register.model";
import i18n from "../../languages/i18n";
import { SET_CURRENT_LANGUAGE, SET_POSITIONS_LIST, SET_SKILLS_LIST } from "../../shared/Storage";
import {
  getPositionsArrayApiCall,
  getSkillsArrayApiCall,
} from "../../redux/actions";

const ChooseLanguage = ({ handleSteps }: PagesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("en");
  const [selectedLang, setSelectedLang] = useState("English");
  const [languages, setLanguages] = useState([
    { label: "Ukrainian", value: "uk" },
    { label: "English", value: "en" },
  ]);

  const {skillsData, positionsData} = useSelector((item: any) => item?.employeeProfileReducer)

  useEffect(() => {
    if (value) {
      (async () => {
        await i18n.changeLanguage(value);
        await SET_CURRENT_LANGUAGE(value);
      })();
      getPositionsArrayApiCall(value)(dispatch);
      getSkillsArrayApiCall(value)(dispatch);
    }
  }, [value]);

  useEffect(() => {
    if (skillsData) {
      SET_SKILLS_LIST(skillsData);
    }
    if (positionsData) {
      SET_POSITIONS_LIST(positionsData);
    }
  }, [skillsData, positionsData]);


  return (
    <>
      <div className="main-wrapper front-layout">
        <section className="choose-option-sec1">
          <div className="back-btn">
            <img src="images/structure/back-icon.svg" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="text-center choose-box">
                  <h5 className="blue70 fontfamily-blackitalic mb-55 fontsize-22 magic-heading">
                    {t("Your prefered language")}
                  </h5>
                  <div className="mb-10">
                    <div className="form-group">
                      <div className="selectDropdown">
                        <button
                          id="SelectClaim"
                          data-bs-toggle="dropdown"
                          className="form-select"
                          aria-expanded="false"
                        >
                          {t(selectedLang)}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-start">
                          {languages.map((lang) => (
                            <li
                              key={lang.value}
                              className="radiobox-image"
                              onClick={() => {
                                setValue(lang.value);
                                setSelectedLang(lang.label);
                              }}
                            >
                              <input
                                type="radio"
                                id="english"
                                name="lang"
                                value={value}
                              />
                              <label>{lang.label}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-40">
                    <button
                      className="btn next-btn"
                      onClick={() => handleSteps(2)}
                    >
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default ChooseLanguage;
