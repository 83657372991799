import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";

import Navbar from "../../components/Navbar";

import LogInSignUpModal from "../../components/Modals/LogInSignUpModal";
import LogoutModal from "../../components/Modals/LogoutModal";

import Chat from "../chat";
import DiscoverEmployee from "../discoverEmployee";
import DiscoverEmployer from "../discoverEmployer";
import EnableXmtp from "../enableXmtp";
import OauthNotify from "../oauthNotify";
import Home from "../home";
import NotFound from "../notFound";
import Profile from "../profile";
import Register from "../register";
import Protected from "./Protected";
import routes from "./routes";

const BrowserContent = () => {
  return (
    <>
      <Navbar />
      <LogInSignUpModal />
      <LogoutModal />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/jobs"} element={<DiscoverEmployee />} />
        <Route path={"/talents"} element={<DiscoverEmployer />} />
        <Route path={"/oauthnotify"} element={<OauthNotify />} />
        <Route element={<Protected />}>
          <Route path={"/enable"} element={<EnableXmtp />} />
          <Route path={"/create"} element={<Register />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/chat"} element={<Chat />} />
        </Route>
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </>
  );
};

const Router = () => (
  <BrowserRouter>
    <BrowserContent />
  </BrowserRouter>
);

export default Router;
