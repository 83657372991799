import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = localStorage.getItem("address");
  return user ? true : false;
};

const Protected = () => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/" />;
};
export default Protected;
