import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_ADDRESS, REMOVE_ADDRESS, REMOVE_USER } from "../../shared/Storage";
import { useWeb3React } from "@web3-react/core";
import { useAppStore } from "../../store/app";

const LogoutModal = () => {
  const [adr, setAdr] = useState<string>("");
  const navigate = useNavigate();
  const { deactivate } = useWeb3React();
  const setClient = useAppStore((state) => state.setClient);
  const setConvoMessages = useAppStore((state) => state.setConvoMessages);
  const setConversations = useAppStore((state) => state.setConversations);
  const setAddress = useAppStore((state) => state.setAddress);
  const setSigner = useAppStore((state) => state.setSigner);

  const location = useLocation();

  const getAbbr = () => {
    if (adr) {
      const len = adr.length;
      if (len < 10) return len;
      return adr?.substr(0, 6) + "..." + adr?.substr(len - 4, len);
    }
  };

  const logout = () => {
    (async () => {
      REMOVE_ADDRESS();
      REMOVE_USER();
      deactivate();
      setClient(undefined);
      setConversations(new Map());
      setConvoMessages(new Map());
      setSigner(undefined);
      setAddress(undefined);
      navigate("/", { replace: true });
    })();
  };

  useEffect(() => {
    (async () => {
      setAdr(await GET_ADDRESS());
    })();
  }, [location.pathname]);

  return (
    <div
      className="modal fade logoutDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="logoutDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="jobtypeBox">
              <h5 className="text-xs-center modal-title black0d fontfamily-semibold mb-15 fontsize-16">
                Account
              </h5>
              <h5
                className="text-xs-center modal-title black0d fontfamily-semibold mb-15 fontsize-16"
                style={{ color: "#704EF8" }}
              >
                {getAbbr()}{" "}
                <i
                  className="fas fa-copy"
                  onClick={() => {
                    navigator.clipboard.writeText(adr);
                  }}
                ></i>
              </h5>
              <div className="mb-10">
                <div className="form-group"></div>
              </div>
              <div className="mt-100 text-center" data-bs-dismiss="modal">
                <button className="btn width200" onClick={() => logout()}>
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
