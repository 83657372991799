import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useListConversations from "../../hooks/useListConversations";
import { useAppStore } from "../../store/app";
import Conversations from "./Conversations";
import EmptyChat from "./EmptyChat";

const Chat = () => {
  const { state } = useLocation();
  useListConversations();
  const conversations = useAppStore((state) => state.conversations);
  const convoMessages = useAppStore((state) => state.convoMessages)
  const [recipientWalletAddr, setRecipientWalletAddr] = useState(
    state?.recipientWalletAddr
  );

  useEffect(() => {
    document.body.classList.add('chat-page');
    return () => {
      document.body.classList.remove('chat-page');
    }
  }, []);

  return (
    <div>
      {conversations.size > 0 || state?.recipientWalletAddr ? (
        <>
          <Conversations
            recipientWalletAddr={recipientWalletAddr}
            setRecipientWalletAddr={setRecipientWalletAddr}
          />
        </>
      ) : (
        <>
          <EmptyChat />
        </>
      )}
    </div>
  );
};

export default Chat;
