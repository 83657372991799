import {
  SAVE_DATA_REDUX,
  POSITIONS_LIST_DATA_REDUX,
  POSITION_DATA_REDUX,
  COUNT_POSITIONS_DATA_REDUX,
  COUNT_EMPLOYER_COLLECTION_DATA_REDUX,
} from '../types/types';

const initialState = {
  loading: false,
  errorMessage: null,
  positionsListData: null,
  positionData: null,
  countPositionsData: null,
  countSavedTalents: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        errorMessage: action.message,
      };
    case POSITIONS_LIST_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        positionsListData: action.data,
        errorMessage: action.message,
      };
    case POSITION_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        positionData: action.data,
        errorMessage: action.message,
      };
    case COUNT_POSITIONS_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        countPositionsData: action.data,
        errorMessage: action.message,
      };
    case COUNT_EMPLOYER_COLLECTION_DATA_REDUX:
      return {
        ...state,
        loading: action.loader,
        countSavedTalents: action.data,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
