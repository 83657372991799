import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editPositionApiCall,
  getCountPositionsApiCall,
  getPositionsListApiCall,
  postNewPositionApiCall,
} from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";

const PostNewPositionModal = () => {
  const dispatch = useDispatch();
  const [jobTitle, setJobTitle] = useState("");
  const [jobDesc, setJobDesc] = useState("");
  const [cityType, setCityType] = useState<number>();
  const [salaryType, setSalaryType] = useState<number>();
  const [skills, setSkills] = useState("");
  const [salaryLow, setSalaryLow] = useState("");
  const [salaryHigh, setSalaryHigh] = useState("");
  const [posting, setPosting] = useState(false);

  const [city, setCity] = useState("Location");
  const currentCity = useSelector((item: any) => item?.userReducer?.city);

  const [openCategory, setOpenCategory] = useState(false);
  const [seletedCategory, setSelectedCategory] = useState("Category");
  const [category, setCategory] = useState([
    { label: "engineering", value: "engineering" },
    { label: "product", value: "product" },
    { label: "design", value: "design" },
    { label: "marketing", value: "marketing" },
    { label: "business", value: "business" },
    { label: "development", value: "development" },
    { label: "sales", value: "sales" },
    { label: "finance", value: "finance" },
    { label: "accounting", value: "accounting" },
    { label: "legal", value: "legal" },
    { label: "operation", value: "operation" },
    { label: "media", value: "media" },
    { label: "other", value: "other" },
  ]);

  const [workTypeValue, setWorkTypeValue] = useState<number>();
  const [workType, setWorkType] = useState("Type");
  const [type, setType] = useState([
    { label: "fulltime", value: 0 },
    { label: "parttime", value: 1 },
    { label: "contract", value: 2 },
    { label: "internship", value: 3 },
    { label: "volunteer", value: 4 },
  ]);

  const onPressPost = async () => {
    setPosting(true);
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      category: seletedCategory,
      city_type: cityType,
      city: city,
      description: jobDesc,
      salary_high: salaryHigh,
      salary_low: salaryLow,
      salary_type: salaryType,
      skills: skills,
      title: jobTitle,
      work_type: workTypeValue,
    };

    await postNewPositionApiCall(body)(dispatch);
    await getPositionsListApiCall(address)(dispatch);
    await getCountPositionsApiCall(address)(dispatch);
    setPosting(false);
  };

  useEffect(() => {
    if(currentCity) {
        setCity(currentCity);
    }
  }, [currentCity])

  return (
    <div
      className="modal fade postNew modalwidth428"
      tabIndex={-1}
      aria-labelledby="postNewLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxbasicInfo">
          <h5 className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center">
            Post New Position
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div>
              <form className="max-330">
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputPostNew"
                    placeholder="Job Title"
                    onChange={(evt) => setJobTitle(evt.target.value)}
                    value={jobTitle}
                  />
                </div>
                <div className="selectDropdown">
                  <button
                    id="SelectClaim"
                    data-bs-toggle="dropdown"
                    className="form-select formInputPostNew"
                    aria-expanded="false"
                  >
                    {seletedCategory}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-start">
                    {category.map((item) => (
                      <li
                        key={item.value}
                        className="radiobox-image"
                        onClick={() => {
                          setSelectedCategory(item.value);
                        }}
                      >
                        <input type="radio" />
                        <label>{item.label}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="selectDropdown mt-20">
                  <button
                    id="SelectClaim"
                    data-bs-toggle="dropdown"
                    className="form-select formInputPostNew"
                    aria-expanded="false"
                  >
                    {workType}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-start">
                    {type.map((item) => (
                      <li
                        key={item.value}
                        className="radiobox-image"
                        onClick={() => {
                          setWorkType(item.label);
                          setWorkTypeValue(item.value);
                        }}
                      >
                        <input type="radio" />
                        <label>{item.label}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-20"></div>
                <div className="selectchips d-flex flex-wrap align-items-center">
                  <div
                    className="category-chips-box"
                    onClick={() => setCityType(0)}
                  >
                    <input type="radio" name="categorySelect" />
                    <div className="category-chips">
                      <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                        Any
                      </p>
                    </div>
                  </div>
                  <div
                    className="category-chips-box"
                    onClick={() => setCityType(1)}
                  >
                    <input type="radio" name="categorySelect" />
                    <div className="category-chips">
                      <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                        Remote
                      </p>
                    </div>
                  </div>
                  <div
                    className="category-chips-box"
                    onClick={() => setCityType(2)}
                  >
                    <input type="radio" name="categorySelect" />
                    <div className="category-chips">
                      <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                        On-Site
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="form-group mt-20"
                  data-bs-toggle="modal"
                  data-bs-target=".citiesDialog"
                >
                  <input
                    type="text"
                    className="form-control formInputPostNew"
                    placeholder="Location"
                    disabled
                    value={city}
                  />
                </div>
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputPostNew"
                    placeholder="Skills Required"
                    onChange={(evt) => setSkills(evt.target.value)}
                    value={skills}
                  />
                </div>

                <div className="salaryRangebox1">
                  <h5 className="modal-title black0d fontfamily-semibold fontsize-16">
                    Salary Range
                  </h5>
                  <div className="selectchipsRange d-flex align-items-center ">
                    <div
                      className="category-chips-box"
                      onClick={() => setSalaryType(0)}
                    >
                      <input type="radio" name="slectsalary" />
                      <div className="category-chips">
                        <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                          Hourly
                        </p>
                      </div>
                    </div>
                    <div
                      className="category-chips-box"
                      onClick={() => setSalaryType(1)}
                    >
                      <input type="radio" name="slectsalary" />
                      <div className="category-chips">
                        <p className="fontsize-12 fontfamily-regular blue70 mb-0">
                          Monthly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="saleryrangepricecolNew d-flex align-items-center text-center">
                    <input
                      type="text"
                      placeholder="Min"
                      value={salaryLow}
                      onChange={(evt) => setSalaryLow(evt.target.value)}
                    />{" "}
                    <img
                      src={require("../../assets/images/structure/line.png").default}
                      alt=""
                      className="line"
                    />{" "}
                    <input
                      type="text"
                      placeholder="Max"
                      value={salaryHigh}
                      onChange={(evt) => setSalaryHigh(evt.target.value)}
                    />{" "}
                    <p
                      className="fofontsize-16 text-uppercase fontfamily-bold mb-0"
                      style={{ color: "rgb(81 42 237 / 87%)" }}
                    >
                      IN USD
                    </p>
                  </div>
                </div>

                <div className="form-group mb-20 mt-20">
                  <textarea
                    className="fontsize-12 form-control formInputPostNew "
                    rows={5}
                    id="comment"
                    name="text"
                    placeholder="Job description"
                    value={jobDesc}
                    onChange={(evt) => setJobDesc(evt.target.value)}
                  ></textarea>
                </div>

                <div className="mb-20 mt-50">
                  <div className="selectchipsBtn d-flex align-items-center ">
                    <div className="category-chips-box">
                      <input type="radio" name="slectsalary" />
                      <div
                        className="category-chips"
                        style={{ backgroundColor: "white" }}
                      >
                        <p className="fontsize-14 fontfamily-regular blue70 mb-0 ">
                          Save
                        </p>
                      </div>
                    </div>
                    <div className="category-chips-box" data-bs-dismiss="modal" onClick={onPressPost}>
                      <input type="radio" name="slectsalary" />
                      <button className="btn" 
                        style={{ backgroundColor: "#704EF8" }}
                        disabled={posting}
                      >
                        <p
                          className="fontsize-14 fontfamily-regular blue70 mb-0 "
                          style={{ color: "white" }}
                        >
                          Post
                          {posting && <span className="icon-spinner" ></span>}                
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostNewPositionModal;
