import {REGISTER_REDUX_AUTH} from '../types/types';
import {
  SERVER_REQUEST,
  CREATE_USER_API,
  EMPLOYER_BASIC_INFO_API,
  EMPLOYEE_BASIC_INFO_API,
} from '../../shared/Backend';
import { SET_USER } from '../../shared/Storage';

export const createUserApiCall =
  (body, navigate, userType) => async dispatch => {
    dispatch({type: REGISTER_REDUX_AUTH, loader: true, message: null});
    try {
      const response = await SERVER_REQUEST(body, CREATE_USER_API, 'POST');
      if (response.message === 'ok') {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: null,
          payload: response.userId,
        });
        SET_USER(JSON.stringify(response));
        navigate('/profile', { replace: true });
      } else {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: REGISTER_REDUX_AUTH,
        loader: false,
        message: error.Message,
      });
    }
  };

export const postEmployerBasicInfoApiCall =
  (body, navigation, route) => async dispatch => {
    dispatch({type: REGISTER_REDUX_AUTH, loader: true, message: null});
    try {
      const response = await SERVER_REQUEST(body, EMPLOYER_BASIC_INFO_API, 'POST');
      if (response.message === 'ok') {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: null,
          payload: response.userId,
        });
        if(route.params?.from === 'profile') {
          navigation.navigate('Profile')
        } else {
          // navigation.dispatch(
          //   CommonActions.reset({
          //     index: 0,
          //     routes: [{name: 'BottomTabBarNavigator'}],
          //   }),
          // );
        }
        
      } else {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: REGISTER_REDUX_AUTH,
        loader: false,
        message: error.Message,
      });
    }
  };

  export const postEmployeeBasicInfoApiCall =
  (body, navigation, route) => async dispatch => {
    dispatch({type: REGISTER_REDUX_AUTH, loader: true, message: null});
    try {
      const response = await SERVER_REQUEST(body, EMPLOYEE_BASIC_INFO_API, 'POST');
      if (response.message === 'ok') {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: null,
          payload: response.userId,
        });
      } else {
        dispatch({
          type: REGISTER_REDUX_AUTH,
          loader: false,
          message: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: REGISTER_REDUX_AUTH,
        loader: false,
        message: error.Message,
      });
    }
  };
