import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getEmployeeWorkListApiCall,
  postWorkApiCall,
} from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 9;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const years = generateArrayOfYears();

const AddWorkExperienceModal = ({ data, setEditItem }: any) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState<any>();
  const [project, setProject] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDesc, setJobDesc] = useState("");
  const [value, setValue] = useState("");
  const [fromMonth, setFromMonth] = useState<number>(1);
  const [fromYear, setFromYear] = useState<number>(years[0]);
  const [toMonth, setToMonth] = useState<number>(1);
  const [toYear, setToYear] = useState<number>(years[0]);

  const currentCity = useSelector((item: any) => item?.userReducer?.city);

  const onPressSaveBtn = async () => {
    const address = await GET_ADDRESS();
    const fromTime = new Date(fromYear + "-" + fromMonth + "-01");
    const toTime = new Date(toYear + "-" + toMonth + "-01");
    const body = {
      address: address,
      project: project,
      position: jobTitle,
      city: currentCity,
      description: jobDesc,
      from_time: fromTime.valueOf(),
      to_time: toTime.valueOf(),
    };
    if (editData) {
      const editBody = { ...body, work_id: editData?.id };
      await postWorkApiCall(editBody)(dispatch);
      setEditItem(null);
      setEditData(null);
    } else {
      await postWorkApiCall(body)(dispatch);
    }
    await getEmployeeWorkListApiCall(address)(dispatch);
  };

  useEffect(() => {
    if (data) {
      setEditData(data);
      setProject(data?.project);
      setJobTitle(data?.position);
      setJobDesc(data?.description);
      setFromMonth(new Date(data?.fromTime).getMonth() + 1);
      setFromYear(new Date(data?.fromTime).getFullYear());
      setToMonth(new Date(data?.toTime).getMonth() + 1);
      setToYear(new Date(data?.toTime).getFullYear());
    }
  }, [data]);

  useEffect(() => {
    if (currentCity) {
      setValue(currentCity);
    }
  }, [currentCity]);

  return (
    <div
      className="modal fade aadworkExperience modalwidth444"
      tabIndex={-1}
      aria-labelledby="aadworkExperienceLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              onClick={() => setEditData(null)}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="workexperiencDiaBox">
              <h5 className="modal-title black0d fontfamily-semibold mb-15 fontsize-16 text-center">
                Add Working Experience
              </h5>
              <div>
                <div className="form-group FmInput">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company / Employer / Project Name"
                    value={project}
                    onChange={(evt) => setProject(evt.target.value)}
                  />
                </div>
                <div className="form-group FmInput">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Job Title"
                    value={jobTitle}
                    onChange={(evt) => setJobTitle(evt.target.value)}
                  />
                </div>
                <div className="from-to-date-field d-flex align-items-center">
                  <div className="left">
                    <label className="labelInp">From</label>

                    <div className="from-toInp from-toInpLeft">
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftLeft"
                          placeholder=""
                          defaultValue={fromMonth}
                          value={fromMonth}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {months.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setFromMonth(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftRight"
                          placeholder=""
                          defaultValue={fromYear}
                          value={fromYear}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {years.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setFromYear(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <label className="labelInp">To</label>
                    <div className="from-toInp from-toInpright">
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftLeft"
                          placeholder=""
                          defaultValue={toMonth}
                          value={toMonth}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {months.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setToMonth(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftRight"
                          placeholder=""
                          defaultValue={toYear}
                          value={toYear}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {years.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setToYear(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group FmInput">
                  <textarea
                    className="form-control"
                    placeholder="Job description (Optional)"
                    value={jobDesc}
                    onChange={(evt) => setJobDesc(evt.target.value)}
                  ></textarea>
                </div>
                <div className="form-group FmInput">
                  <div className="mb-20">
                    <label className="labelInp fontsize-14">
                      Work Location (Optional)
                    </label>
                  </div>
                  <div
                    className="form-group form-input searchInput"
                    data-bs-toggle="modal"
                    data-bs-target=".citiesDialog"
                  >
                    <input
                      placeholder={"Enter a city name"}
                      className="form-control"
                      style={{ background: "#F3F3F3" }}
                      disabled
                      defaultValue={value}
                    />
                    <img
                      src={
                        require("../../assets/images/structure/search.svg")
                          .default
                      }
                      alt=""
                      className="searchIcon"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-50" data-bs-dismiss="modal">
                <button className="btn width120" onClick={onPressSaveBtn}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWorkExperienceModal;
