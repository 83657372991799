const AppliedPositionModal = () => {
  return (
    <div
      className="modal fade appliedposition modalwidth828"
      tabIndex={-1}
      aria-labelledby="appliedpositionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
                <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="positionItemBox">
              <h5 className="modal-title black0d fontfamily-semibold mb-30 fontsize-16 text-center">
                Applied Positions
              </h5>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="productListBlock">
                    <div className="product-row1 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/products/product1.png").default}
                        className="prodImage"
                        alt=""
                      />
                      <div className="pl-15">
                        <p className="fontsize-16 black fontfamily-medium mb-0">
                          Graphic Designer
                        </p>
                        <p className="fontsize-14 gray82 fontfamily-medium mb-0">
                          Apple{" "}
                          <img
                            src={require("../../assets/images/structure/star.svg").default}
                            alt=""
                            className="starIcon"
                          />
                        </p>
                        <p className="fontsize-12 gray82 fontfamily-regular mb-0">
                          Posted 2022/07/01
                        </p>
                      </div>
                      <div className="likebox ml-auto">
                        <img
                          src={require("../../assets/images/structure/save.svg").default}
                          alt=""
                          className="saveIcon1"
                        />
                        <img
                          src={require("../../assets/images/structure/save-pink.svg").default}
                          alt=""
                          className="saveIcon2"
                        />
                      </div>
                    </div>
                    <div className="product-row3 d-flex align-items-center">
                      <div className="d-flex flex-wrap align-items-center">
                        <span className="chips chips-fill-orange mb-0">
                          Full-time
                        </span>
                        <span className="chips chips-fill-green mb-0">
                          Remote
                        </span>
                      </div>
                      <div className="ml-auto">
                        <p className="fontsize-16 fontfamily-semibold blue70 mb-0">
                          $2k~$5k / Month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedPositionModal;
