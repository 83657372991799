import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getEmployeeEducationListApiCall,
  postEducationApiCall,
} from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 9;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const years = generateArrayOfYears();

const degrees = [
  { label: "Certificate", value: "Certificate" },
  { label: "Associate", value: "Associate" },
  { label: "Bachelor", value: "Bachelor" },
  { label: "Master", value: "Master" },
  { label: "PhD", value: "PhD" },
  { label: "MD", value: "MD" },
  { label: "JD", value: "JD" },
  { label: "Dropout", value: "Dropout" },
  { label: "Exchange Program", value: "Exchange Program" },
  { label: "Other", value: "Other" },
];

const AddEducationExperienceModal = ({ data, setEditItem }: any) => {
  const dispatch = useDispatch();

  const [editData, setEditData] = useState<any>();
  const [schoolName, setSchoolName] = useState("");
  const [majorTitle, setMajorTitle] = useState("");
  const [degree, setDegree] = useState("Degree");
  const [fromMonth, setFromMonth] = useState<number>(1);
  const [fromYear, setFromYear] = useState<number>(years[0]);
  const [toMonth, setToMonth] = useState<number>(1);
  const [toYear, setToYear] = useState<number>(years[0]);

  const onPressSaveBtn = async () => {
    const address = await GET_ADDRESS();
    const fromTime = new Date(fromYear + "-" + fromMonth + "-01");
    const toTime = new Date(toYear + "-" + toMonth + "-01");
    const body = {
      address: address,
      school: schoolName,
      degree: degree,
      major: majorTitle,
      from_time: fromTime.valueOf(),
      to_time: toTime.valueOf(),
    };
    
    if (editData !== null) {
      const editBody = { ...body, education_id: editData?.id };
      await postEducationApiCall(editBody)(dispatch);
      setEditItem(null);
      setEditData(null);
    } else {
      await postEducationApiCall(body)(dispatch);
    }

    await getEmployeeEducationListApiCall(address)(dispatch);
  };

  useEffect(() => {
    if (data) {
      setEditData(data);
      setSchoolName(data?.school);
      setMajorTitle(data?.major);
      setDegree(data?.degree);
      setFromMonth(new Date(data?.fromTime).getMonth() + 1);
      setFromYear(new Date(data?.fromTime).getFullYear());
      setToMonth(new Date(data?.toTime).getMonth() + 1);
      setToYear(new Date(data?.toTime).getFullYear());
    }
  }, [data]);

  return (
    <div
      className="modal fade aadEducational modalwidth444"
      tabIndex={-1}
      aria-labelledby="aadEducationalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              onClick={() => setEditData(null)}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="workexperiencDiaBox">
              <div>
                <div className="form-group FmInput">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="School Name"
                    value={schoolName}
                    onChange={(evt) => setSchoolName(evt.target.value)}
                  />
                </div>
                <div className="FmInput">
                  <button
                    id="SelectClaim"
                    data-bs-toggle="dropdown"
                    className="form-select"
                    aria-expanded="false"
                  >
                    {degree}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-start">
                    {degrees.map((item) => (
                      <li
                        key={item.value}
                        className="radiobox-image"
                        onClick={() => setDegree(item.value)}
                      >
                        <input
                          type="radio"
                          id="Degree1"
                          name="Degree"
                          defaultValue={degree}
                        />
                        <label>{item.label}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="form-group FmInput">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Major Title"
                    value={majorTitle}
                    onChange={(evt) => setMajorTitle(evt.target.value)}
                  />
                </div>
                <div className="from-to-date-field d-flex align-items-center">
                  <div className="left">
                    <label className="labelInp">From</label>

                    <div className="from-toInp from-toInpLeft">
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftLeft"
                          placeholder=""
                          defaultValue={fromMonth}
                          value={fromMonth}
                          data-bs-toggle="dropdown" 
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {months.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setFromMonth(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftRight"
                          placeholder=""
                          defaultValue={fromYear}
                          value={fromYear}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {years.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setFromYear(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <label className="labelInp">To</label>
                    <div className="from-toInp from-toInpright">
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftLeft"
                          placeholder=""
                          defaultValue={toMonth}
                          value={toMonth}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {months.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setToMonth(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="selectDropdown">
                        <input
                          type="text"
                          className="form-control ftRight"
                          placeholder=""
                          defaultValue={toYear}
                          value={toYear}
                          data-bs-toggle="dropdown"
                        />
                        <ul className="dropdown-menu dropdown-menu-start">
                          {years.map((item) => (
                            <li
                              key={item}
                              className="radiobox-image"
                              onClick={() => {
                                setToYear(item);
                              }}
                            >
                              <input type="radio" />
                              <label>{item}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-120" data-bs-dismiss="modal" >
                <button className="btn width120" onClick={onPressSaveBtn}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEducationExperienceModal;
