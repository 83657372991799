const NotFound = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h2>404 Page not found</h2>
      </div>
    </>
  );
};

export default NotFound;
