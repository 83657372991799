import {GET_TOKEN} from './Storage';

export const ADD_EDUCATION_API = 'employee/add_education';
export const ADD_POSITION_API = 'position/add_position';
export const ADD_WORK_API = 'employee/add_work';
export const COUNT_EMPLOYEE_COLLECTION_API = 'collection/count_employee_positions';
export const COUNT_EMPLOYER_COLLECTION_API = 'collection/count_employer_employees';
export const COUNT_POSITIONS_API = 'position/count_positions_by_employer_address';
export const CREATE_USER_API = 'user/create';
export const DISCOVER_EMPLOYEES_API = 'discover/discover_employees_by_employer_address';
export const DISCOVER_POSITIONS_API = 'discover/discover_positions_by_employee_address';
export const EDIT_POSITION_API = 'position/edit_position';
export const EMPLOYEE_BASIC_INFO_API = 'employee/extend_employee';
export const EMPLOYER_BASIC_INFO_API = 'employer/extend_employer';
export const GET_CITY_ARRAY = 'data/get_city_array';
export const GET_EDUCATION_LIST_API = 'employee/get_educations';
export const GET_EMPLOYEE_COLLECTION_API = 'collection/employee_get_positions';
export const GET_EMPLOYER_COLLECTION_API = 'collection/employer_get_employees';
export const GET_EXTENDED_USER_API = 'user/get_extended_user_by_address';
export const GET_POSITION_BY_ID_API = 'position/get_position_by_position_id';
export const GET_POSITIONS_ARRAY_API = 'data/get_position_array';
export const GET_POSITIONS_BY_ADDRESS_API = 'position/get_positions_by_employer_address';
export const GET_SKILLS_ARRAY_API = 'data/get_skill_array';
export const GET_USER_DATA_API = 'user/get_user_by_address';
export const GET_USERS_ARRAY_API = 'user/get_user_array_by_address_array'
export const GET_WORK_LIST_API = 'employee/get_works';
export const REMOVE_EMPLOYEE_COLLECTION_API = 'collection/employee_remove_position';
export const REMOVE_EMPLOYEE_EDUCATION_API = 'employee/remove_education';
export const REMOVE_EMPLOYEE_WORK_API = 'employee/remove_work';
export const REMOVE_EMPLOYER_COLLECTION_API = 'collection/employer_remove_employee';
export const REMOVE_POSITION_API = 'position/remove_position';
export const SAVE_EMPLOYEE_COLLECTION_API = 'collection/employee_save_position';
export const SAVE_EMPLOYEE_DESIRED_POSITION = 'employee/save_employee_desired_position';
export const SAVE_EMPLOYEE_SKILLS_API = 'employee/save_employee_skills';
export const SAVE_EMPLOYER_COLLECTION_API = 'collection/employer_save_employee';
export const UPLOAD_PROFILE_IMAGE_API = 'user/upload_profile_image';
export const START_OF_AN_CHAT_API = 'chat/start_of_an_chat';
export const COUNT_OF_CHAT_API = 'chat/count_of_chat';
export const UPDATE_USER_CITY_API = 'user/update_user_city';
export const UPDATE_USER_LANGUAGE_API = 'user/update_user_language';
export const VERIFY_EMAIL_SEND = 'email/verify_send';
export const VERIFY_EMAIL_CONFIRM = 'email/verify_confirm';

export const SERVER_REQUEST = async (body, endpoint, reqType, dataType) => {
  let response;
  let url = `${process.env.REACT_APP_API_KEY}${endpoint}`;

  const _headers = await GET_HEADER();

  const FETCH_TIMEOUT = 120000;
  let didTimeOut = false;

  return new Promise(async (resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, FETCH_TIMEOUT);

    if (reqType.toLowerCase() === 'get') {
      response = await fetch(url, {
        method: reqType.toUpperCase(),
        headers: _headers,
      });
    } else if (reqType.toLowerCase() === 'put') {
      response = await fetch(url, {
        method: reqType.toUpperCase(),
        headers: _headers,
        body: JSON.stringify(body),
      });
    } else if (reqType.toLowerCase() === 'delete') {
      response = await fetch(url, {
        method: reqType.toUpperCase(),
        headers: _headers,
        // body: JSON.stringify(body)
      });
    } else if (dataType) {
      response = await fetch(url, {
        method: reqType.toUpperCase(),
        body: body,
      });
    } else {
      response = await fetch(url, {
        method: reqType.toUpperCase(),
        headers: _headers,
        body: JSON.stringify(body),
      });
    }

    clearTimeout(timeout);
    if (!didTimeOut) {
      const res = await response.json();
      resolve(res);
    }
  });
};

const GET_HEADER = async () => {
  const token = await GET_TOKEN();
  return new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  });
};
