import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import {
  getEmployeeWorkListApiCall,
  getEmployeeEducationListApiCall,
} from "../../redux/actions";


const TalentDetailsModal = ({ details }: any) => {

  const dispatch = useDispatch();

  const { workListData, educationListData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );

  const navigate = useNavigate();
  const onPressTalkNow = () => {
    navigate("/chat", { state: { recipientWalletAddr: details?.address } });
  };

  const getEducations = async () => {
    await getEmployeeEducationListApiCall(details?.address)(dispatch);
  };

  const getWorks = async () => {
    await getEmployeeWorkListApiCall(details?.address)(dispatch);
  };

  const formateDate = (item: any) => {
    const fromTime =
    String(new Date(item?.fromTime).getMonth() + 1).padStart(2, '0') +
      " / " +
    new Date(item?.fromTime).getFullYear();

    const toTime =
    String(new Date(item?.toTime).getMonth() + 1).padStart(2, '0')  +
      " / " +
    new Date(item?.toTime).getFullYear();

    return fromTime + " - " + toTime;
  };

  useEffect(() => {
    getEducations();
    getWorks();
  }, [details]);


  return (
    <div
      className="modal fade employeetalktoHR modalwidth864"
      tabIndex={-1}
      aria-labelledby="employeetalktoHRDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="lg-only" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="empDetailBox">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/products/portrait_default.png").default}
                      alt=""
                      className="empImage"
                    />
                    <h6 className="fontfamily-semibold mb-0 fontsize-16 black mt-25">
                      {details?.name}{" "}
                    </h6>
                    <h6 className="fontfamily mb-0 fontsize-14 black ">
                      {details?.desiredPosition}
                    </h6>

                    <span style={{ paddingTop: 5, display: "block" }}>
                      <img src="" alt="" className="lg-only" height="15px" />
                      <p className="gray80 fontfamily-regular mb-0 fontsize-12">
                        <img
                          src={require("../../assets/images/structure/university.png").default}
                          alt=""
                          className=""
                          height="15px"
                        />
                        {details?.highestGraduateSchool}
                      </p>
                    </span>
                    <span className="row">
                      <p className="gray80 fontfamily-regular mb-0 fontsize-12">
                        <img
                          src="images/structure/breifcase.svg"
                          alt=""
                          className=""
                          height="15px"
                        />{" "}
                        {+(details?.workMonths / 12).toFixed(1) + ' years'}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div className="employee-description">
                    <div className="d-flex align-items-center mb-0 employee-info">
                      <p className="gray80 fontfamily-medium mb-0 fontsize-14">
                        <img
                          src={
                            require("../../assets/images/structure/location.svg")
                              .default
                          }
                          alt=""
                          className="locationIcon mr-10"
                        />
                        {details?.city}
                      </p>
                      <div className="ml-auto employee-tag">
                        <img
                          src={
                            require("../../assets/images/structure/share-black.svg")
                              .default
                          }
                          alt=""
                          className="shareIcon mr-25"
                          width="20px"
                        />
                        <img
                          src={
                            require("../../assets/images/structure/save.svg")
                              .default
                          }
                          alt=""
                          className="saveIcon"
                          width="20px"
                        />
                      </div>
                    </div>

                    <div className="mt-30 employe-content">
                      <div className="mb-20">
                        <h6 className="mb-1 fontfamily-medium mb-20 fontsize-14 black">
                          Skill:
                        </h6>
                        <p className="gray80 fontfamily-regular black04 mb-0 fontsize-14">
                          {details?.skills && details?.skills.split(",").join(", ")}
                        </p>
                      </div>

                      <div className="mb-20">
                        <h6 className="fontfamily-medium mb-10 fontsize-14 black">
                          Education:
                        </h6>
                        <ul className="pl-0 des-ul">
                        {educationListData?.map((item: any) => (
                          <li className="gray80 fontfamily-regular black04 mb-10 fontsize-14 ml-20" key={item}>
                            {formateDate(item)} <br/>{item?.school} - {item?.degree}<br/> {item?.major}
                          </li>
                        ))}
                        </ul>
                      </div>
                      <div className="mb-20">
                        <h6 className="fontfamily-medium mb-10 fontsize-14 black">
                          Work:
                        </h6>
                        <ul className="pl-0 des-ul">
                        {workListData?.map((item: any) => (
                          <li className="gray80 fontfamily-regular black04 mb-10 fontsize-14 ml-20" key={item}>
                            {formateDate(item)} <br/>{item?.position}<br/> {item?.description}
                          </li>
                        ))}
                        </ul>
                      </div>
                    </div>
                    <div
                      data-bs-dismiss="modal"
                      className="talknowBox1 text-center mt-35"
                      onClick={onPressTalkNow}
                    >
                      <div className="ml-auto">
                        <div className="btn width150 btn-rad8 btnTalk talknowBox">
                          Talk Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentDetailsModal;
