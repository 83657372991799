import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPositionByIdApiCall } from "../../redux/actions";

const workTypes = [
  "Full-time",
  "Part-time",
  "Contract",
  "Internship",
  "Volunteer",
];

const cityTypes = ["Any", "Remote", "On-site"];

const salaryTypes = ["Hour", "Month"];

const PositionDetailsModal = ({ details }: any) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { positionData } = useSelector(
    (item: any) => item?.employerProfileReducer
  );

  const onPressTalkNow = () => {
    navigate("/chat", {
      state: { recipientWalletAddr: positionData?.employer?.address },
    });
  };

  const formateDate = (date: string) => {
    const postedDate = new Date(date);
    const formatedDate = new Date(postedDate).toLocaleDateString("en-ZA");
    return formatedDate;
  };

  const fetchPositionDetails = async () => {
    if (!details) {
      return;
    }

    await getPositionByIdApiCall(details?.id)(dispatch);
  };

  useEffect(() => {
    fetchPositionDetails();
  }, [details]);

  return (
    <div
      className="modal fade employeetalktoHR modalwidth864"
      tabIndex={-1}
      aria-labelledby="employeetalktoHRDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="lg-only" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="empDetailBox">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="text-center">
                    <img
                      src={
                        require("../../assets/images/products/product-dtl.png")
                          .default
                      }
                      alt=""
                      className="empImage"
                    />
                    <h6 className="fontfamily-semibold mb-2 fontsize-16 black mt-25">
                      {positionData?.title}
                    </h6>
                    <h6 className="fontfamily-semibold mb-10 fontsize-14 black">
                      {positionData?.employer?.project}
                      <img
                        src={
                          require("../../assets/images/structure/star.svg")
                            .default
                        }
                        alt=""
                        className="starIcon"
                      />
                    </h6>
                    <p className="fontsize-12 gray82 fontfamily-medium mb-0">
                      {`Posted ${formateDate(positionData?.updatedAt)}`}
                    </p>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div className="employee-description">
                    <div className="d-flex align-items-center mb-15 employee-info">
                      <span className="chips chips-outline-orange mb-0 mr-15">
                        {workTypes[positionData?.salaryType]}{" "}
                      </span>{" "}
                      <p className="fontsize-16 fontfamily-semibold blue70 mb-0">
                        {`$${positionData?.salaryLow}~$${
                          positionData?.salaryHigh
                        } / ${salaryTypes[positionData?.salaryType]}`}
                      </p>
                      <div className="ml-auto employee-tag">
                        <img
                          src={
                            require("../../assets/images/structure/share-black.svg")
                              .default
                          }
                          alt=""
                          className="shareIcon mr-25"
                        />{" "}
                        <img
                          src={
                            require("../../assets/images/structure/collection-pink.svg")
                              .default
                          }
                          alt=""
                          className="saveIcon"
                        />
                      </div>
                    </div>
                    <p className="gray80 fontfamily-medium mb-0 fontsize-14 city-name text-md-end">
                      <img
                        src={
                          require("../../assets/images/structure/location.svg")
                            .default
                        }
                        alt=""
                        className="locationIcon mr-10"
                      />{" "}
                      {positionData?.city.split(",")[0]}
                    </p>

                    <div className="mt-50 employe-content">
                      <div className="mb-20">
                        <h6 className="fontfamily-medium mb-20 fontsize-14 black">
                          Description:
                        </h6>
                        <p className="gray80 fontfamily-regular black04 mb-0 fontsize-14">
                          {positionData?.description}
                        </p>
                      </div>
                    </div>

                    <div className="talknowBox d-flex align-items-center">
                      <img
                        src={
                          require("../../assets/images/products/p.png").default
                        }
                        alt=""
                        className="talkPImg mr-24"
                      />
                      <div className="pl-10">
                        <p className="white fontfamily-regular mb-1 fontsize-12">
                          {positionData?.employer?.position}
                        </p>
                        <p className="white fontfamily-regular mb-1 fontsize-10">
                          {positionData?.employer?.name}
                        </p>
                      </div>
                      <div
                        className="ml-auto"
                        data-bs-dismiss="modal"
                        onClick={onPressTalkNow}
                      >
                        <div className="btn outline-white width150 btn-rad8">
                          Talk Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionDetailsModal;
