import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postEmployerBasicInfoApiCall, getEmployerDataApiCall } from "../../redux/actions";
import { GET_ADDRESS } from "../../shared/Storage";
import {useTranslation} from "react-i18next";

const EmployerBasicInfoModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { employerData } = useSelector((item: any) => item.userReducer);
  const [isEmployerCreated, setIsEmployerCreated] = useState(true);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [project, setProject] = useState('');
  const [website, setWebsite] = useState('');
  const [twitter, setTwitter] = useState('');
  const [jobDesc, setJobDesc] = useState('');
  const [loading, setLoading] = useState(false);

  const onPressNext = async () => {
    setLoading(true);
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      project: project,
      website: website,
      twitter: twitter,
      description: jobDesc,
      name: name,
      position: position,
    };
   await postEmployerBasicInfoApiCall(body)(dispatch);
   await getEmployerDataApiCall(address)(dispatch);
   setIsEmployerCreated(true)
   setLoading(false);
   document.querySelector<HTMLElement>('.employer-basicinfo-close')?.click();
  };

  useEffect(() => {
    if (employerData) {
      if (employerData.err) {
        setIsEmployerCreated(false);
      } else {
        setIsEmployerCreated(true);
        setName(employerData?.name);
        setPosition(employerData?.position);
        setProject(employerData?.project);
        setWebsite(employerData?.website);
        setTwitter(employerData?.twitter);
        setJobDesc(employerData?.description);
      }
    }
  }, [employerData]);

  return (
    <div
      className={"modal fade" + (!isEmployerCreated ? " show d-block " : " ") + "edit modalwidth428"}
      tabIndex={-1}
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxbasicInfo">
          <h5 className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center">
            Your Basic Info
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close employer-basicinfo-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsEmployerCreated(true)}
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div>
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputEdit "
                    placeholder="Your Name"
                    
                    onChange={evt => setName(evt.target.value)}
                    value={name}
                  />
                </div>
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputEdit "
                    placeholder="Your Position"
                   
                    onChange={evt => setPosition(evt.target.value)}
                    value={position}
                  />
                </div>
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputEdit "
                    placeholder="Company / Employer / Project Name"
                    onChange={evt => setProject(evt.target.value)}
                    value={project}
                    
                  />
                </div>
                <div className="form-group mt-20">
                  <input
                    type="text"
                    className="form-control formInputEdit "
                    placeholder="Official Website"
                    onChange={evt => setWebsite(evt.target.value)}
                    value={website}
                  />
                </div>

                <div className="form-group mt-20">
                  <div className="fontsize-12 gray80 mb-1 fontfamily-medium d-flex align-items-center">
                    <span className="textOption">
                      {" "}
                      <img
                        src={require("../../assets/images/structure/logo3.png").default}
                        alt=""
                        className="ml-10"
                      />
                      (Optional) @
                    </span>
                    <input
                      type="text"
                      className="form-control inputTwitter"
                      placeholder="Twitter Handle"
                      onChange={evt => setTwitter(evt.target.value)}
                      value={twitter}
                    />
                  </div>
                </div>

                <div className="form-group mb-20">
                  <textarea
                    className="fontsize-12 form-control formInputEdit "
                    rows={5}
                    id="comment"
                    name="text"
                    placeholder="Company / Project description"
                    onChange={evt => setJobDesc(evt.target.value)}
                    value={jobDesc}
                  ></textarea>
                </div>
                <div className="mb-20 mt-50 text-center next-btn">
                  <button className="btn width200" onClick={onPressNext} disabled={loading}>{t('Save')}
                    {loading && <span className="icon-spinner" ></span>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerBasicInfoModal;
