import { useEffect, useRef, useState } from "react";
import { GET_ADDRESS } from "../../shared/Storage";
import {
  sendVerifyEmailApiCall,
  verifyEmailCodeApiCall,
} from "../../redux/actions";
import { useDispatch } from "react-redux";


const EmailVerificationModal = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [isCodeCorrectFormat, setIsCodeCorrectFormat] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const onPressVerify = async () => {
    setVerifying(true);
    const address = await GET_ADDRESS();
    await verifyEmailCodeApiCall({address, code: emailCode, email})(dispatch);
    setVerifying(false);
  };

  const emailValidate = () => {
    let EmailReg = /^\S+@\S+\.\S+$/;
    if (!EmailReg.test(email)) {
      return false;
    }
    return true;
  };

  const handleEmail = (text: string) => {
    text = text.replace(/[^0-9a-zA-Z_\@\-\.]/g, '');
    setEmail(text);
  };

  const handleEmailCode = (text: string) => {
    text = text.replace(/[^0-9]/g, '');
    setEmailCode(text);
  };

  useEffect(() => {
    if (emailCode.length === 6 && email) {
      setIsCodeCorrectFormat(true);
    } else {
      setIsCodeCorrectFormat(false);
    }
  }, [emailCode]);

  return (
    <div
      className="modal fade emailModal verify-modal modalwidth428"
      tabIndex={-1}
      aria-labelledby="emailModalLabel"
      aria-hidden="true"
    >
      <div className="alert alert-success verifyEmailCode hideModal" role="alert">
        Code was send successfully
      </div>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalBoxVerify">
          <h5
            className="fofontsize-14  fontfamily-bold mb-0  mt-25 text-center"
            style={{ color: "#090909" }}
          >
            Verify your Offcial E-mail
          </h5>
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div className="form-group mt-20">
                <input
                  type="text"
                  className="form-control formInput "
                  placeholder="xxxx@web3.com"
                  value={email}
                  onChange={(evt) => handleEmail(evt.target.value)}
                />
              </div>
              <div className="form-group">
                <div>
                  <input
                    type="text"
                    className="form-control formInput"
                    placeholder="Email verification code"
                    aria-label="Email verification code"
                    value={emailCode}
                    onChange={(evt) => handleEmailCode(evt.target.value)}
                  />
                  <Timer
                    emailValidate={emailValidate}
                    email={email}
                    setEmailCode={setEmailCode}
                    sendVerifyEmailApiCall={sendVerifyEmailApiCall}
                    dispatch={dispatch}
                  />
                </div>
              </div>
            </div>
            <p className="fontsize-12 gray82 mt-20 text-center">
              *Only support business E-mails, please do <br />
              not use your personal E-mails
            </p>
          </div>
          <div className="mb-40 text-center">
            <button
              className="btn width100"
              disabled={!isCodeCorrectFormat || verifying}
              onClick={onPressVerify}
            >
              Verify
              {verifying && <span className="icon-spinner" ></span>}                
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Timer = (props: any) => {
  const initialSeconds = 0;
  const [seconds, setSeconds] = useState(initialSeconds);

  const startCountDown = () => {
    setSeconds(60);
  };

  const sendEmailApiCall = async (email: string) => {
    const address = await GET_ADDRESS();
    await props.sendVerifyEmailApiCall({address, email})(props.dispatch);
  };

  const sendEmail = async () => {
    if (props.emailValidate()) {
      props.setEmailCode('');
      startCountDown();
      sendEmailApiCall(props.email);
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div
      className={(seconds === 0 ? "sideInput" : "sideInput grayVerifyContainer")}
      onClick={() => sendEmail()}
      >
      {seconds === 0 ? (
        <span className="fontCountDown">Send Code</span>
      ) : (
        <div className="fontCountDown">
          {seconds < 10 ? `0${seconds}` : seconds}
        </div>
      )}
    </div>
  );
};

export default EmailVerificationModal;
