import { useEffect, useState } from "react";

import Footer from "../../components/Footer";
import { GET_CURRENT_ROLE } from "../../shared/Storage";
import EmployeeProfile from "../employeeProfile";
import EmployerProfile from "../employerProfile";

const Profile = () => {
  const [userType, setUserType] = useState<string>("0");

  useEffect(() => {
    (async () => {
      const role = await GET_CURRENT_ROLE();
      setUserType(role || "0");
    })();
  }, []);
  return userType === "0" ? (
    <>
      <EmployerProfile />
      <Footer />
    </>
  ) : (
    <>
      <EmployeeProfile />
      <Footer />
    </>
  );
};
export default Profile;
