import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getEmployeeDataApiCall,
  postEmployeeDesiredPositionApiCall,
} from "../../redux/actions";
import { BACKGROUND_COLOR } from "../../shared/Colors";
import { GET_ADDRESS } from "../../shared/Storage";
import SearchResultItem from "../SearchResultItem/SearchResultItem";
import {useTranslation} from "react-i18next";

const DesiredPositionModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [desiredPosition, setDesiredPosition] = useState("");
  const { positionsData } = useSelector(
    (item: any) => item?.employeeProfileReducer
  );
  const [popularPositions, setPopularPositions] = useState<any>([]);

  const searchForPosition = () => {
    if (value.length > 0) {
      const searchText = value.toLowerCase();
      let filtredPositions: string[] = [];
      positionsData?.map((item: string) => {
        const currentItem = item.toLowerCase();
        if (currentItem === searchText || currentItem.startsWith(searchText)) {
          filtredPositions.push(item);
        }
      });
      setSearchResult(filtredPositions);
    } else {
      setSearchResult(positionsData);
    }
  };

  const onPressItem = (item: string) => {
    setValue("");
    setDesiredPosition(item);
  };

  const onPressConfirm = async () => {
    const address = await GET_ADDRESS();
    const body = {
      address: address,
      desired_position: desiredPosition,
    };
    await postEmployeeDesiredPositionApiCall(body)(dispatch);
    await getEmployeeDataApiCall(address)(dispatch);
  };

  useEffect(() => {
    searchForPosition();
  }, [value]);

  useEffect(() => {
    if (positionsData) {
      setPopularPositions([...popularPositions, ...positionsData.slice(0, 8)]);
    }
    return () => {
      setPopularPositions([]);
    };
  }, [positionsData]);

  return (
    <div
      className="modal fade desiredDialog modalwidth444"
      tabIndex={-1}
      aria-labelledby="desiredDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="" style={{ borderBottom: 0 }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={
                  require("../../assets/images/structure/close-circle-outline.svg")
                    .default
                }
                alt=""
              />
            </button>
          </div>
          <div className="modal-body">
            <h5 className="black0d fontfamily-semibold mb-55 fontsize-16 text-center">
              {t('Desired Position')}
            </h5>
            <div className="citiesbox">
              <div className="citiessearchbox d-flex align-items-center justify-content-center">
                <div className="form-group mb-0 form-input searchInput w-100">
                  <input
                    type="text"
                    placeholder="Enter a position name"
                    className="form-control"
                    value={desiredPosition}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                      setDesiredPosition(evt.target.value);
                    }}
                  />
                  <img
                    src={
                      require("../../assets/images/structure/search.svg")
                        .default
                    }
                    alt=""
                    className="searchIcon"
                  />
                </div>
              </div>
              {value.length !== 0 && (
                <div style={{
                  width: "80%",
                  height: '50%',
                  position: 'absolute',
                  backgroundColor: BACKGROUND_COLOR,
                  opacity: 0.9,
                  paddingLeft: 48,
                  left: 50,
                }}>
                  {searchResult?.map((item, index) => (
                    <SearchResultItem
                      key={index}
                      onPress={() => onPressItem(item)}
                      text={desiredPosition}
                      result={item}
                    />
                  ))}
                </div>
              )}
              <div className="divider mt-25 mb-25"></div>
              <p className="black0d fontfamily-semibold mb-15 fontsize-16">
                Popular Positions
                {/* <span style={{ color: "gray" }}>(1/1)</span> */}
              </p>
              <div className="d-flex flex-wrap mt-15">
                {popularPositions?.map((item: string, index: number) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        item === desiredPosition
                          ? "rgba(112, 78, 248, 0.25)"
                          : "",
                    }}
                    className="cityChips"
                    onClick={() => setDesiredPosition(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="mt-30 text-center" data-bs-dismiss="modal">
                <button className="btn width200" onClick={onPressConfirm}>CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesiredPositionModal;

// const styles = {
//   searchWrapper: {
//     width: "100%",
//     //height: '20%',
//     position: 'absolute',
//     backgroundColor: BACKGROUND_COLOR,
//     opacity: 0.9,
//     paddingLeft: 48,
//     left: 20,
//   },
// };
