import {
  DISCOVER_EMPLOYEES_LIST_REDUX,
  POSITIONS_LIST_DATA_REDUX,
  EMPLOYEE_COLLECTION_DATA_REDUX,
  EMPLOYER_COLLECTION_DATA_REDUX,
} from '../types/types';

const initialState = {
  city: '',
  loading: false,
  errorMessage: null,
  employeeListData: null,
  discoverPositionsData: null,
  employeeCollectionData: null,
  employerCollectionData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCOVER_EMPLOYEES_LIST_REDUX:
      return {
        ...state,
        employeeListData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case POSITIONS_LIST_DATA_REDUX:
      return {
        ...state,
        discoverPositionsData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
    case EMPLOYEE_COLLECTION_DATA_REDUX:
      return {
        ...state,
        employeeCollectionData: action.data,
        loading: action.loader,
        errorMessage: action.message,
      };
      case EMPLOYER_COLLECTION_DATA_REDUX: 
        return {
          ...state,
          employerCollectionData: action.data,
          loading: action.loader,
          errorMessage: action.message,
        }
    default:
      return state;
  }
};

export default reducer;
